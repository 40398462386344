import getInstance from './instance';

const BASE_URL: string = '/file';

const FileApi = {
  create: async (params: models.CreateFile) => {
    const instance = await getInstance();
    const { data } = await instance.post(BASE_URL, params);

    return data;
  },
};

export default FileApi;
