import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { translate } from '~/services/i18n';

import Icon from '../../assets/svg/ic_404.svg';
interface IProps {
  routes: models.route[];
}

const NotFound: React.FC = () => {
  const loggedUser = useSelector(
    (state: reducers.rootReducer) => state.auth.me
  );

  return (
    <div className="not-found">
      <div className="not-found__inner">
        <Row className="justify-content-center">
          <Col md={12}>

          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NotFound;
