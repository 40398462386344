import { PlusCircleOutlined } from '@ant-design/icons';
import { CellParams } from '@material-ui/data-grid';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useReduxState } from '~/hooks/useReduxState';

import * as ScheduleActions from '~/actions/schedule';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import DataTable from '~/components/DataTable/DataTable';
import DataTableActions from '~/components/DataTableActions/DataTableActions';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import PanelContentSearchBar from '~/components/PanelContentSearchBar/PanelContentSearchBar';
import { REPORT_PAGE_SIZE } from '~/config/env';
import { translate } from '~/services/i18n';
import NavigationService from '~/services/navigation';
import { getRouteStackPath } from '~/config/routes';
import { getEventTypeTitle, getScopeTypeTitle } from '~/enum/typeEvent';

const initialValues: advancedFilterModels.IScheduleAdvancedFilter = {
  name: '',
  page: 1,
  sort: 'desc',
  orderBy: 'createdAt',
  pageSize: REPORT_PAGE_SIZE,
};

const ScheduleReport: React.FC = () => {
  const dispatch = useDispatch();
  const [advancedFilters, setAdvancedFilters] = useState(initialValues);
  const { schedule } = useReduxState();

  useEffect(() => {
    const filter = NavigationService.getQuery();
    onSearch({
      ...advancedFilters,
      ...filter,
    });
  }, []);

  const onSearch = (filters: advancedFilterModels.IScheduleAdvancedFilter) => {
    dispatch(ScheduleActions.getReport(filters));
  };

  const onRemove = (id: string) => {
    dispatch(ScheduleActions.remove(id));
  };

  return (
    <div className="report">
      <PanelContentSearchBar
        onFormSubmit={(form: any) =>
          onSearch({
            ...advancedFilters,
            name: form.search,
          })
        }
      />
      <div className="report__header">
        <Row>
          <Col lg={6}>
            <PanelContentHeader
              pageTitle={translate(
                'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.TITLE'
              )}
              pageDescription={translate(
                'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.DESCRIPTION'
              )}
            />
          </Col>
          <Col lg={6} className="text-right">
            <Link
              to={`${getRouteStackPath(
                'ACTIVITIES',
                'SCHEDULE_DETAILS'
              )}/${translate('SHARED.ADD_ID')}`}
            >
              <AdvancedButton
                text={translate('SHARED.ADD_NEW')}
                endIcon={<PlusCircleOutlined />}
              />
            </Link>
          </Col>
        </Row>
      </div>

      <div className="report__table">
        <Row>
          <Col>
            <div className="report__table__inner">
              <DataTable
                rows={schedule?.list?.rows || []}
                rowCount={schedule?.list?.count || 0}
                columns={[
                  {
                    field: translate(
                      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.FIELDS.ID.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.FIELDS.ID.TITLE'
                    ),
                    sortable: false,
                    hide: true,
                    flex: 1,
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.FIELDS.TITLE.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.FIELDS.TITLE.TITLE'
                    ),
                    flex: 1,
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.FIELDS.DESCRIPTION.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.FIELDS.DESCRIPTION.TITLE'
                    ),
                    flex: 1,
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.FIELDS.SCOPE.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.FIELDS.SCOPE.TITLE'
                    ),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return <>{getScopeTypeTitle(o.value as number)}</>;
                    },
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.FIELDS.TYPE.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.FIELDS.TYPE.TITLE'
                    ),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return <>{getEventTypeTitle(o.value as number)}</>;
                    },
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.FIELDS.START_EVENT.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.REPORT.FIELDS.START_EVENT.TITLE'
                    ),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return (
                        <>
                          {DateTime.fromISO(o.value as string).toLocaleString(
                            DateTime.DATE_SHORT
                          )}
                        </>
                      );
                    },
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.USER.ADMIN.REPORT.FIELDS.ACTIONS.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.USER.ADMIN.REPORT.FIELDS.ACTIONS.TITLE'
                    ),
                    align: 'center',
                    headerAlign: 'center',
                    renderCell: (o: CellParams) => (
                      <DataTableActions
                        row={o.row}
                        basePath={getRouteStackPath(
                          'ACTIVITIES',
                          'SCHEDULE_DETAILS'
                        )}
                        onRemove={onRemove}
                      />
                    ),
                  },
                ]}
                pageSize={advancedFilters.pageSize}
                page={advancedFilters.page}
                sort={advancedFilters.sort}
                orderBy={advancedFilters.orderBy}
                onChange={(filters) => {
                  const searchFilters = {
                    ...advancedFilters,
                    ...filters,
                  };
                  setAdvancedFilters(searchFilters);
                  onSearch(searchFilters);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ScheduleReport;
