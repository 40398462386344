import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import SynodDetails from './Details/SynodDetails';
import SynodReport from './Report/SynodReport';
import { getPagetType } from '~/utils/page';
import { PAGE_TYPE } from '~/enum/pageType';
import AdminDetails from '~/pages/User/Admin/Details/AdminDetails';

const synodNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('USER', 'SYNOD_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.USER.SYNOD.REPORT.TITLE')}
          pageDescription={translate('PAGES.PANEL.USER.SYNOD.REPORT.DESCRIPTION')}
        >
          <SynodReport />
        </PanelContent>
      </Route>

      <Route path={`${getRouteStackPath('USER', 'SYNOD_DETAILS')}/:id`}>
        <PanelContent
          pageTitle={translate(`PAGES.PANEL.USER.SYNOD.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
          pageDescription={translate(`PAGES.PANEL.USER.SYNOD.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
        >
          <SynodDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default synodNavigationStack;
