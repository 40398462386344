import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '~/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import ParishDetails from './Details/ParishDetails';
import ParishReport from './Report/ParishReport';
import { getPagetType } from '~/utils/page';
import { PAGE_TYPE } from '~/enum/pageType';

const ParishNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('USER', 'PARISH_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.USER.PARISH.REPORT.TITLE')}
          pageDescription={translate('PAGES.PANEL.PARISH.REPORT.PAGE_DESCRIPTION')}
        >
          <ParishReport />
        </PanelContent>
      </Route>
      <Route path={`${getRouteStackPath('USER', 'PARISH_DETAILS')}/:id`}>
        <PanelContent
          pageTitle={translate(`PAGES.PANEL.USER.PARISH.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
          pageDescription={translate(`PAGES.PANEL.USER.PARISH.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
        >
          <ParishDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default ParishNavigationStack;
