import { GET_CEP } from '~/actions/actionTypes';

const initialState: reducers.UtilsReducer = {
  list: {
    bairro: null,
    cep: null,
    localidade: null,
    logradouro: null,
    uf: null,
    complemento: null,
  }
};

const utilsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_CEP:
      return {
        ...state,
        list: action.payload.data,
      };
    default:
      return state;
  }
};

export default utilsReducer;
