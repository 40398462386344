import { COMMUNITY_ALL, COMMUNITY_DETAIL, COMMUNITY_REPORT } from '~/actions/actionTypes';

const initialState: reducers.CommunityReducer = {
  detail: null,
  list: null,
  all: [],
};

const CommunityReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case COMMUNITY_DETAIL:
      state = {
        ...state,
        detail: action.payload,
      };
      break;

    case COMMUNITY_REPORT:
      state = {
        ...state,
        list: action.payload,
      };
      break;

    case COMMUNITY_ALL:
      state = {
        ...state,
        all: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default CommunityReducer;
