import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
  CheckCircleOutlined,
  UploadOutlined,
  PlusCircleOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import AdvancedUploader from '~/components/AdvancedUploader/AdvancedUploader';
import PanelContentBreadcrumb from '~/components/PanelContentBreadcrumb/PanelContentBreadcrumb';

import * as AnnouncementActions from '~/actions/announcement';
import * as FileActions from '~/actions/file';
import * as MessageService from '~/services/message';
import { maskDateTime } from '~/services/masks';
import { translate } from '~/services/i18n';
import { getRouteStackPath } from '~/config/routes';

const formInitialValues: models.Announcement = {
  title: '',
  file: null,
  description: '',
  date: '',
  type: 1,
};

interface IFile {
  url: string;
}

const PasswordsDetails: React.FC = () => {
  const [form, setForm] = useState(formInitialValues);

  const dispatch = useDispatch();

  const onFormChange = (key: string, value: string) => {
    setForm((prevState: models.Announcement) => ({
      ...prevState,
      [key]: value,
    }));
    if (key === 'file')
      MessageService.success(
        translate('PAGES.PANEL.PASSWORDS.MESSAGES.FILE_SUCCESS')
      );
  };

  const onFormSubmit = () => {
    if (!form.file)
      return MessageService.error(
        translate('PAGES.PANEL.PASSWORDS.MESSAGES.SUBMIT_ERROR')
      );
    if (
      !DateTime.fromFormat(form.date as string, 'dd/mm/yyyy hh:mm')
        .toJSDate()
        .getTime()
    )
      return MessageService.error(
        'datainvalida'
        // translate('PAGES.PANEL.PASSWORDS.MESSAGES.INVALID_DATE')
      );

    const { extension, name, base64 } = form.file;

    dispatch(
      FileActions.create(
        {
          type: 1,
          extension,
          name,
          base64,
        },
        (file: models.FileItem) => {
          if (file) {
            dispatch(
              AnnouncementActions.create({
                title: form.title,
                description: form.description,
                type: form.type,
                fileUrl: file.url,
                date: DateTime.fromFormat(
                  form.date as string,
                  'dd/mm/yyyy hh:mm'
                ).toISO(),
              })
            );
          }
        }
      )
    );
  };

  return (
    <Container fluid className="details">
      <Row>
        <Col>
          <PanelContentBreadcrumb
            items={[
              {
                title: translate('PAGES.PANEL.PASSWORDS.TITLE'),
                url: getRouteStackPath('ACTIVITIES', 'PASSWORDS_REPORT'),
                active: true,
              },
              {
                title: translate('PAGES.PANEL.PASSWORDS.REPORT.BUTTON_LINK'),
                active: false,
              },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="details__title">
            <h3 className="details__title__text">
              {translate('PAGES.PANEL.PASSWORDS.TITLE')}
            </h3>
            <p className="details__title__description">
              {translate('PAGES.PANEL.PASSWORDS.REPORT.DESCRIPTION')}
            </p>
          </div>
          <AdvancedForm onSubmit={onFormSubmit}>
            <div className="details__form">
              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.PASSWORDS.LABELS.TITLE')}
                    value={form.title}
                    onChange={(val: string) => onFormChange('title', val)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <AdvancedInput
                    multiline
                    label={translate(
                      'PAGES.PANEL.PASSWORDS.LABELS.DESCRIPTION'
                    )}
                    value={form.description}
                    onChange={(value: string) =>
                      onFormChange('description', value)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.PASSWORDS.LABELS.DATE')}
                    value={maskDateTime(form.date)}
                    onChange={(value: string) => onFormChange('date', value)}
                    placeholder={translate(
                      'PAGES.PANEL.PASSWORDS_ADD.REPORT.ADD.LABELS.DATE_PLACEHOLDER'
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <AdvancedUploader
                    onChange={(value: string) => onFormChange('file', value)}
                    value={form.file}
                    hidePreview
                    mp3
                  >
                    <AdvancedButton
                      text={translate('PAGES.PANEL.PASSWORDS.LABELS.UPLOAD')}
                      endIcon={<UploadOutlined />}
                    />
                  </AdvancedUploader>
                </Col>
              </Row>
              <div className="details__form__actions">
                <Row>
                  <Col md={6}>
                    <Link
                      to={getRouteStackPath('ACTIVITIES', 'PASSWORDS_REPORT')}
                    >
                      <AdvancedButton
                        variant="text"
                        text={translate('SHARED.BACK')}
                        startIcon={<LeftOutlined />}
                      />
                    </Link>
                  </Col>
                  <Col md={6} className="text-right">
                    <AdvancedButton
                      type="submit"
                      text={translate('SHARED.SAVE')}
                      endIcon={<PlusCircleOutlined />}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </AdvancedForm>
        </Col>
      </Row>
    </Container>
  );
};

export default PasswordsDetails;
