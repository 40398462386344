import { INSTITUTIONAL_REPORT, INSTITUTIONAL_UPDATE } from "@portal/actions/actionTypes";

const initialState: reducers.InstitutionalReducer = {
  list: {
    ieclb_init_title: {
      defaultValue: {
        value: '',
      }
    },
    ieclb_init_description: {
      defaultValue: {
        value: '',
      }
    },
    ieclb_video_url: {
      defaultValue: {
        value: '',
      }
    },
    ieclb_main_title: {
      defaultValue: {
        value: '',
      }
    },
    ieclb_init_other_info: {
      defaultValue: {
        value: '',
      }
    },
    ieclb_org_title: {
      defaultValue: {
        value: '',
      }
    },
    ieclb_org_description: {
      defaultValue: {
        value: '',
      }
    },
    ieclb_ecum_title: {
      defaultValue: {
        value: '',
      }
    },
    ieclb_ecum_description: {
      defaultValue: {
        value: '',
      }
    },
    ieclb_inst_title: {
      defaultValue: {
        value: '',
      }
    },
    ieclb_inst_description: {
      defaultValue: {
        value: '',
      }
    },
    ieclb_how_to_join_text: {
      defaultValue: {
        value: '',
      }
    },
    ieclb_how_to_join_title: {
      defaultValue: {
        value: '',
      }
    },
    ieclb_our_history_text: {
      defaultValue: {
        value: '',
      }
    },
    ieclb_our_history_title: {
      defaultValue: {
        value: '',
      }
    },
    ieclb_mission_text: {
      defaultValue: {
        value: '',
      }
    },
    ieclb_mission_title: {
      defaultValue: {
        value: '',
      }
    }
  },
};

const InstitutionalReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case INSTITUTIONAL_REPORT:
      return {
        ...state,
        list: action.payload
      };

    case INSTITUTIONAL_UPDATE:
      return {
        ...state,
        list: action.payload
      };

    default:
      return state;
  }
};

export default InstitutionalReducer;