import * as MessageService from '~/services/message';
import centralNavigationService from '~/services/navigation';

import { USER_DETAIL, USER_REPORT, USER_ME } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';
import UserRequests from '~/repositories/user';
import SynodRequests from '~/repositories/synod';
import { ProfileType } from '@portal/enum/profileType';

export const cleanDetails = () => async (dispatch: any) => {
  dispatch({
    payload: null,
    type: USER_DETAIL,
  });
};

export const getMe = () => async (dispatch: any) => {
  dispatch(increaseLoading());

  try {
    const payload = await UserRequests.me();
    dispatch({
      payload,
      type: USER_ME,
    });
  } catch (err) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getReport = (
  searchParams: advancedFilterModels.IUserAdvancedFilter
) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.User> = await UserRequests.getReport(
      {
        ...searchParams,
        offset: searchParams.page - 1,
        limit: searchParams.pageSize,
      }
    );

    dispatch({
      payload,
      type: USER_REPORT,
    });
  } catch (err) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getDetail = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.User = await UserRequests.getDetail(id);

    dispatch({
      payload,
      type: USER_DETAIL,
    });
  } catch (err) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const create = (params: models.User) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.User = await UserRequests.create(params);

    if (payload.synodId && payload.profileType === ProfileType.SYNOD_ADMIN) {
      await SynodRequests.associateToSynod(payload.synodId, payload.id);
    }

    MessageService.success(
      'PAGES.PANEL.USER.USER.MESSAGES.SUCCESS_ADD_MESSAGE'
    );
    await centralNavigationService.back();
  } catch (err) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const remove = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await UserRequests.remove(id);
    MessageService.success(
      'PAGES.PANEL.USER.USER.MESSAGES.SUCCESS_REMOVE_MESSAGE'
    );
  } catch (err) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const update = (id?: string, params?: models.User) => async (
  dispatch: any
) => {
  dispatch(increaseLoading());
  try {
    await UserRequests.update(id, params);

    if (params?.synodId && params?.profileType === ProfileType.SYNOD_ADMIN) {
      await SynodRequests.associateToSynod(params?.synodId, params.id);
    }

    MessageService.success(
      'PAGES.PANEL.USER.USER.MESSAGES.SUCCESS_EDIT_MESSAGE'
    );
    await centralNavigationService.back();
  } catch (err) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};
