import {
  RightCircleOutlined,
  LeftOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Collapse } from 'antd';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  editInstitutional,
  getInstitutionalReport,
} from '@portal/actions/institutional';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import { translate } from '~/services/i18n';
import { getRouteStackPath } from '~/config/routes';

interface IFormItem {
  value: string;
  type: string;
}

interface IInitialData {
  formTitle: IFormItem;
  formDescription: IFormItem;
  formUrl: IFormItem;
  formOtherInfo: IFormItem;
  participateTitle: IFormItem;
  participateDescription: IFormItem;
  historyTitle: IFormItem;
  historyDescription: IFormItem;
  missionTitle: IFormItem;
  missionDescription: IFormItem;
}

const institutionalDataInitialValues: IInitialData = {
  formTitle: {
    value: '',
    type: '',
  },
  formDescription: {
    value: '',
    type: '',
  },
  formUrl: {
    value: '',
    type: '',
  },
  formOtherInfo: {
    value: '',
    type: '',
  },
  participateTitle: {
    value: '',
    type: '',
  },
  participateDescription: {
    value: '',
    type: '',
  },
  historyTitle: {
    value: '',
    type: '',
  },
  historyDescription: {
    value: '',
    type: '',
  },
  missionTitle: {
    value: '',
    type: '',
  },
  missionDescription: {
    value: '',
    type: '',
  },
};

interface IPanelInstitutionalForm {
  url?: boolean;
  title?: boolean;
  description?: boolean;
  participate?: boolean;
  history?: boolean;
  mission?: boolean;
  otherInfo?: boolean;
  sender: 'init' | 'org' | 'ecum' | 'inst';
}

const InstitutionalForm = ({
  url,
  title,
  description,
  participate,
  history,
  mission,
  otherInfo,
  sender,
}: IPanelInstitutionalForm) => {
  const dispatch = useDispatch();

  const institutional = useSelector(
    (state: reducers.rootReducer) => state.institutional.list
  );

  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState(institutionalDataInitialValues);

  const onFormSubmit = () => {
    const data: { [key: string]: any } = {};
    Object.entries(formData).forEach(
      ([key, value]) => (data[value.type] = value.value)
    );

    dispatch(editInstitutional(data));
  };

  const { Panel } = Collapse;

  const onSearch = async () => {
    await dispatch(getInstitutionalReport());
    setLoading(false);
  };

  const setValues = () => {
    const data = {
      formTitle: {
        value: '',
        type: '',
      },
      formDescription: {
        value: '',
        type: '',
      },
      formUrl: {
        value: institutional.ieclb_video_url.defaultValue.value,
        type: 'ieclb_video_url',
      },
      formOtherInfo: {
        value: institutional.ieclb_init_other_info.defaultValue.value,
        type: 'ieclb_init_other_info',
      },
      participateTitle: {
        value: institutional.ieclb_how_to_join_title.defaultValue.value,
        type: 'ieclb_how_to_join_title',
      },
      participateDescription: {
        value: institutional.ieclb_how_to_join_text.defaultValue.value,
        type: 'ieclb_how_to_join_text',
      },
      historyTitle: {
        value: institutional.ieclb_our_history_title.defaultValue.value,
        type: 'ieclb_our_history_title',
      },
      historyDescription: {
        value: institutional.ieclb_our_history_text.defaultValue.value,
        type: 'ieclb_our_history_text',
      },
      missionTitle: {
        value: institutional.ieclb_mission_title.defaultValue.value,
        type: 'ieclb_mission_title',
      },
      missionDescription: {
        value: institutional.ieclb_mission_text.defaultValue.value,
        type: 'ieclb_mission_text',
      },
    };

    if (sender === 'init') {
      data.formTitle = {
        value: institutional.ieclb_init_title.defaultValue.value,
        type: 'ieclb_init_title',
      };

      data.formDescription = {
        value: institutional.ieclb_init_description.defaultValue.value,
        type: 'ieclb_init_description',
      };
    } else if (sender === 'org') {
      data.formTitle = {
        value: institutional.ieclb_org_title.defaultValue.value,
        type: 'ieclb_org_title',
      };

      data.formDescription = {
        value: institutional.ieclb_org_description.defaultValue.value,
        type: 'ieclb_org_description',
      };
    } else if (sender === 'ecum') {
      data.formTitle = {
        value: institutional.ieclb_ecum_title.defaultValue.value,
        type: 'ieclb_ecum_title',
      };

      data.formDescription = {
        value: institutional.ieclb_ecum_description.defaultValue.value,
        type: 'ieclb_ecum_description',
      };
    } else if (sender === 'inst') {
      data.formTitle = {
        value: institutional.ieclb_inst_title.defaultValue.value,
        type: 'ieclb_inst_title',
      };

      data.formDescription = {
        value: institutional.ieclb_inst_description.defaultValue.value,
        type: 'ieclb_inst_description',
      };
    }

    setFormData(data);
  };

  const handleValueChange = (key: string, value: string, type: string) => {
    setFormData((prevState: any) => ({ ...prevState, [key]: { value, type } }));
  };

  const handleSetValueBasedOnSender = (
    key: string,
    value: string,
    type: 'title' | 'description'
  ) => {
    let newType: string = '';
    if (sender === 'init') {
      newType =
        type === 'title' ? 'ieclb_init_title' : 'ieclb_init_description';
    } else if (sender === 'org') {
      newType = type === 'title' ? 'ieclb_org_title' : 'ieclb_org_description';
    } else if (sender === 'ecum') {
      newType =
        type === 'title' ? 'ieclb_ecum_title' : 'ieclb_ecum_description';
    } else if (sender === 'inst') {
      newType =
        type === 'title' ? 'ieclb_inst_title' : 'ieclb_inst_description';
    }

    handleValueChange(key, value, newType);
  };

  useEffect(() => {
    if (loading) {
      onSearch();
    } else {
      setValues();
    }
  }, [loading]);

  return (
    <div className="institutional-form">
      <div className="institutional-form__items">
        <AdvancedForm onSubmit={onFormSubmit}>
          <div className="institutional-form__items__item institutional-form__items__item--add">
            {url && (
              <Row>
                <Col lg={12}>
                  <AdvancedInput
                    onChange={(e: any) =>
                      handleValueChange('formUrl', e, 'ieclb_video_url')
                    }
                    value={formData.formUrl.value}
                    label={translate('PAGES.PANEL.INSTITUTIONAL.FORM.URL')}
                  />
                </Col>
              </Row>
            )}
            {title && (
              <Row>
                <Col lg={12}>
                  <AdvancedInput
                    onChange={(e: any) =>
                      handleSetValueBasedOnSender('formTitle', e, 'title')
                    }
                    value={formData.formTitle.value}
                    label={translate('PAGES.PANEL.INSTITUTIONAL.FORM.TITLE')}
                  />
                </Col>
              </Row>
            )}
            {description && (
              <Row>
                <Col lg={12}>
                  <AdvancedInput
                    onChange={(e: any) =>
                      handleSetValueBasedOnSender(
                        'formDescription',
                        e,
                        'description'
                      )
                    }
                    value={formData.formDescription.value}
                    multiline={10}
                    rows={6}
                    label={translate(
                      'PAGES.PANEL.INSTITUTIONAL.FORM.DESCRIPTION'
                    )}
                  />
                </Col>
              </Row>
            )}
            {otherInfo && (
              <Row>
                <Col lg={12}>
                  <AdvancedInput
                    onChange={(e: any) =>
                      handleValueChange(
                        'formOtherInfo',
                        e,
                        'ieclb_init_other_info'
                      )
                    }
                    value={formData.formOtherInfo.value}
                    multiline={10}
                    rows={6}
                    label={translate(
                      'PAGES.PANEL.INSTITUTIONAL.FORM.OTHER_INFO'
                    )}
                  />
                </Col>
              </Row>
            )}
            {participate && (
              <Row>
                <Col lg={12}>
                  <Collapse>
                    <Panel
                      header={translate(
                        'PAGES.PANEL.INSTITUTIONAL.FORM.PARTICIPATE'
                      )}
                      key="1"
                    >
                      <AdvancedInput
                        onChange={(e: any) =>
                          handleValueChange(
                            'participateTitle',
                            e,
                            'ieclb_how_to_join_title'
                          )
                        }
                        value={formData.participateTitle.value}
                        label={translate(
                          'PAGES.PANEL.INSTITUTIONAL.FORM.PARTICIPATE_TITLE'
                        )}
                      />

                      <AdvancedInput
                        onChange={(e: any) =>
                          handleValueChange(
                            'participateDescription',
                            e,
                            'ieclb_how_to_join_text'
                          )
                        }
                        value={formData.participateDescription.value}
                        multiline={10}
                        rows={6}
                        label={translate(
                          'PAGES.PANEL.INSTITUTIONAL.FORM.PARTICIPATE'
                        )}
                      />
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            )}
            {history && (
              <Row>
                <Col lg={12}>
                  <Collapse>
                    <Panel
                      header={translate(
                        'PAGES.PANEL.INSTITUTIONAL.FORM.HISTORY'
                      )}
                      key="1"
                    >
                      <AdvancedInput
                        onChange={(e: any) =>
                          handleValueChange(
                            'historyTitle',
                            e,
                            'ieclb_our_history_title'
                          )
                        }
                        value={formData.historyTitle.value}
                        label={translate(
                          'PAGES.PANEL.INSTITUTIONAL.FORM.HISTORY_TITLE'
                        )}
                      />

                      <AdvancedInput
                        onChange={(e: any) =>
                          handleValueChange(
                            'historyDescription',
                            e,
                            'ieclb_our_history_text'
                          )
                        }
                        value={formData.historyDescription.value}
                        multiline={10}
                        rows={6}
                        label={translate(
                          'PAGES.PANEL.INSTITUTIONAL.FORM.HISTORY'
                        )}
                      />
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            )}
            {mission && (
              <Row>
                <Col lg={12}>
                  <Collapse>
                    <Panel
                      header={translate(
                        'PAGES.PANEL.INSTITUTIONAL.FORM.MISSION'
                      )}
                      key="1"
                    >
                      <AdvancedInput
                        onChange={(e: any) =>
                          handleValueChange(
                            'missionTitle',
                            e,
                            'ieclb_mission_title'
                          )
                        }
                        value={formData.missionTitle.value}
                        label={translate(
                          'PAGES.PANEL.INSTITUTIONAL.FORM.MISSION_TITLE'
                        )}
                      />

                      <AdvancedInput
                        onChange={(e: any) =>
                          handleValueChange(
                            'missionDescription',
                            e,
                            'ieclb_mission_text'
                          )
                        }
                        value={formData.missionDescription.value}
                        multiline={10}
                        rows={6}
                        label={translate(
                          'PAGES.PANEL.INSTITUTIONAL.FORM.MISSION'
                        )}
                      />
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            )}
            <div className="details__form__actions">
              <Row>
                <Col md={6}>
                  <Link to={getRouteStackPath('DASHBOARD', 'DETAILS')}>
                    <AdvancedButton
                      variant="text"
                      text={translate('SHARED.BACK')}
                      startIcon={<LeftOutlined />}
                    />
                  </Link>
                </Col>
                <Col md={6} className="text-right">
                  <AdvancedButton
                    type="submit"
                    text={translate('SHARED.SAVE')}
                    endIcon={<PlusCircleOutlined />}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </AdvancedForm>
      </div>
    </div>
  );
};

export default InstitutionalForm;
