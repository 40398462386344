import React from 'react';
import { Col, Row } from 'react-bootstrap';

import OrganizationImage from '~/assets/png/organization.png';
import InstitutionalForm from '~/components/InstitutionalForm/InstitutionalForm';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import { translate } from '~/services/i18n';

const IeclbReport: React.FC = () => {
  return (
    <div className="organization">
      <Row>
        <Col lg={12}>
          <PanelContentHeader
            pageTitle={translate('PAGES.PANEL.ORGANIZATION.REPORT.PAGE_TITLE')}
            pageDescription={translate(
              'PAGES.PANEL.ORGANIZATION.REPORT.PAGE_DESCRIPTION'
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <InstitutionalForm
            title
            description
            sender='org'
          />
        </Col>
        <Col lg={6}>
          <img src={OrganizationImage} />
        </Col>
      </Row>
    </div>
  );
};

export default IeclbReport;
