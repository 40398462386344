import { translate } from '../services/i18n';

export const moneyFormat = (value: number): string => {
  let response = null;
  try {
    response = new Intl.NumberFormat(translate('SETTINGS.LANG'), {
      currency: translate('SETTINGS.MONEY'),
      style: 'currency',
    }).format(value);
  } catch (e) {
    throw Error(e);
  }

  return response;
};

export const phoneFormat = (value: string): string => {
  let match;
  if (value) { match = value.match(/^(\d{2})(\d{5})(\d{4})$/); }
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  return 'Número Inválido';
};

export const removeSpecialChars = (str: string) => {
  return str ? str.replace(/[^A-Za-z0-9]/g, '').replace(/\/s/g, '') : '';
};

export const formatDateTime = (date: string | null | undefined, time: string | null | undefined) => {
  const splited_date = date?.split('/')
  const splited_time = time?.split(':')
  let formated_date = null;

  if (splited_date && splited_time) {
    formated_date = new Date(
      parseInt(splited_date[2]),
      parseInt(splited_date[1]) - 1,
      parseInt(splited_date[0]),
      parseInt(splited_time[0] + 3),
      parseInt(splited_time[1]),
      0);
  }

  return formated_date;
}