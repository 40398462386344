import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '~/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import UserDetails from './Details/UserDetails';
import UserReport from './Report/UserReport';
import { getPagetType } from '~/utils/page';
import { PAGE_TYPE } from '~/enum/pageType';

const UserNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('USER', 'USER_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.USER.USER.REPORT.TITLE')}
          pageDescription={translate('PAGES.PANEL.USER.USER.REPORT.DESCRIPTION')}
        >
          <UserReport />
        </PanelContent>
      </Route>
      <Route path={`${getRouteStackPath('USER', 'USER_DETAILS')}/:id`}>
        <PanelContent
          pageTitle={translate(`PAGES.PANEL.USER.USER.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
          pageDescription={translate(`PAGES.PANEL.USER.USER.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
        >
          <UserDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default UserNavigationStack;
