import {
  ANNOUNCEMENT_DETAIL,
  ANNOUNCEMENT_REPORT,
} from '~/actions/actionTypes';

const initialState: reducers.AnnouncementReducer = {
  detail: null,
  list: null,
};

const AnnouncementReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ANNOUNCEMENT_DETAIL:
      state = {
        ...state,
        detail: action.payload,
      };
      break;

    case ANNOUNCEMENT_REPORT:
      state = {
        ...state,
        list: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default AnnouncementReducer;
