import { PlusCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { CellParams } from '@material-ui/data-grid';

import { TYPE_EVENT } from '@portal/enum/typeEvent';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedFilters from '~/components/AdvancedFilters/AdvancedFilters';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import PanelContentSearchBar from '~/components/PanelContentSearchBar/PanelContentSearchBar';
import { translate } from '~/services/i18n';
import NavigationService from '~/services/navigation';
import { useReduxState } from '@portal/hooks/useReduxState';
import { getNotifications } from '@portal/actions/notification';
import DataTable from '~/components/DataTable/DataTable';
import { DateTime } from 'luxon';
import { getRouteStackPath } from '@portal/config/routes';

const initialValues: advancedFilterModels.INotificationsAdvancedFilter = {
  title: '',
  description: '',
  fileUrl: '',
  date: '',
  type: TYPE_EVENT.SINODAL,
  pageSize: 10,
  page: 1,
  orderBy: 'createdAt',
  sort: 'desc',
};

const NotificationsReport: React.FC = () => {
  const dispatch = useDispatch();
  const [advancedFilters, setAdvancedFilters] = useState(initialValues);
  const { notification } = useReduxState();

  useEffect(() => {
    const filter = NavigationService.getQuery();
    onSearch({
      ...advancedFilters,
      ...filter,
    });
  }, []);

  const onSearch = (
    filters: advancedFilterModels.INotificationsAdvancedFilter
  ) => {
    dispatch(getNotifications(filters))
  };

  return (
    <div className="notifications">
      <div className="notifications__advanced-filters">
        <PanelContentSearchBar
          onFormSubmit={(form: any) => onSearch({
            ...advancedFilters,
            title: form.search,
          })}
          advancedFilter={
            <AdvancedFilters
              onFilter={() => onSearch(advancedFilters)}
              cols={[3, 2, 2, 3]}
              fields={[
                {
                  label: translate(
                    'PAGES.PANEL.NOTIFICATIONS.REPORT.ADVANCED_FILTER.TITLE'
                  ),
                  onChange: (title: string) => {
                    setAdvancedFilters({
                      ...advancedFilters,
                      title,
                    });
                  },
                  type: 'input',
                  value: advancedFilters.title,
                },
                {
                  label: translate(
                    'PAGES.PANEL.NOTIFICATIONS.REPORT.ADVANCED_FILTER.DATE'
                  ),
                  onChange: (date: string) => {
                    setAdvancedFilters({
                      ...advancedFilters,
                      date,
                    });
                  },
                  type: 'input',
                  value: advancedFilters.date,
                },
              ]}
            />
          }
        />
      </div>

      <div className="notifications__header">
        <Row>
          <Col lg={9}>
            <PanelContentHeader
              pageTitle={translate(
                'PAGES.PANEL.NOTIFICATIONS.REPORT.PAGE_TITLE'
              )}
              pageDescription={translate(
                'PAGES.PANEL.NOTIFICATIONS.REPORT.PAGE_DESCRIPTION'
              )}
            />
          </Col>
          <Col lg={3} className="text-right">
            <Link to={getRouteStackPath('ACTIVITIES', 'NOTIFICATIONS_DETAILS')}>
              <AdvancedButton
                className="notifications__header__advanced-button"
                text={translate('PAGES.PANEL.NOTIFICATIONS.REPORT.BUTTON_LINK')}
                endIcon={<PlusCircleOutlined />}
              />
            </Link>
          </Col>
        </Row>
      </div>

      <div className="report__table">
        <Row>
          <Col>
            <div className="report__table__inner">
              <DataTable
                rows={notification?.list?.rows || []}
                rowCount={notification?.list?.count || 0}
                columns={[
                  {
                    field: translate('PAGES.PANEL.NOTIFICATIONS.REPORT.FIELDS.ID.KEY'),
                    headerName: translate('PAGES.PANEL.NOTIFICATIONS.REPORT.FIELDS.ID.TITLE'),
                    sortable: false,
                    hide: true,
                    flex: 1,
                  },
                  {
                    field: translate('PAGES.PANEL.NOTIFICATIONS.REPORT.FIELDS.TITLE.KEY'),
                    headerName: translate('PAGES.PANEL.NOTIFICATIONS.REPORT.FIELDS.TITLE.TITLE'),
                    flex: 1,
                  },
                  {
                    field: translate('PAGES.PANEL.NOTIFICATIONS.REPORT.FIELDS.BODY.KEY'),
                    headerName: translate('PAGES.PANEL.NOTIFICATIONS.REPORT.FIELDS.BODY.TITLE'),
                    flex: 1,
                  },
                  {
                    field: translate('PAGES.PANEL.NOTIFICATIONS.REPORT.FIELDS.CREATED_AT.KEY'),
                    headerName: translate('PAGES.PANEL.NOTIFICATIONS.REPORT.FIELDS.CREATED_AT.TITLE'),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return (
                        <>
                          {DateTime.fromISO(o.value as string).toLocaleString(
                            DateTime.DATE_SHORT
                          )}
                        </>
                      );
                    },
                  },
                ]}
                pageSize={advancedFilters.pageSize}
                page={advancedFilters.page}
                sort={advancedFilters.sort}
                orderBy={advancedFilters.orderBy}
                onChange={(filters) => {
                  const searchFilters = {
                    ...advancedFilters,
                    ...filters,
                  };
                  setAdvancedFilters(searchFilters);
                  onSearch(searchFilters);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NotificationsReport;
