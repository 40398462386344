import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import { translate } from '@portal/services/i18n';
import PanelContent from '~/components/PanelContent/PanelContent';
import InitialReport from '~/pages/MySynod/InitialReport/InitialReport';

import LeadersReport from './LeadersReport/LeadersReport';
import OccupationAreaReport from './OccupationAreaReport/OccupationAreaReport';

const MySynodNavigationStack = () => {
  return (
    <Switch>
      <Route
        path={
          getRouteStackPath(translate('PAGES.PANEL.MY_SYNOD.ROUTE.NAME'),
            translate('PAGES.PANEL.MY_SYNOD.ROUTE.INITIAL')
          )}
      >
        <PanelContent
          pageTitle={translate('PAGES.PANEL.MY_SYNOD.INITIAL.PAGE_TITLE')}
          pageDescription={translate('PAGES.PANEL.MY_SYNOD.INITIAL.PAGE_DESCRIPTION')}
        >
          <InitialReport />
        </PanelContent>
      </Route>

      <Route
        path={
          getRouteStackPath(translate('PAGES.PANEL.MY_SYNOD.ROUTE.NAME'),
            translate('PAGES.PANEL.MY_SYNOD.ROUTE.LEADERS')
          )}
      >
        <PanelContent
          pageTitle={translate('PAGES.PANEL.MY_SYNOD.INITIAL.PAGE_TITLE')}
          pageDescription={translate('PAGES.PANEL.MY_SYNOD.INITIAL.PAGE_DESCRIPTION')}
        >
          <LeadersReport />
        </PanelContent>
      </Route>

      <Route
        path={
          getRouteStackPath(translate('PAGES.PANEL.MY_SYNOD.ROUTE.NAME'),
            translate('PAGES.PANEL.MY_SYNOD.ROUTE.OCCUPATION_AREA')
          )}
      >
        <PanelContent
          pageTitle={translate('PAGES.PANEL.MY_SYNOD.INITIAL.PAGE_TITLE')}
          pageDescription={translate('PAGES.PANEL.MY_SYNOD.INITIAL.PAGE_DESCRIPTION')}
        >
          <OccupationAreaReport />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default MySynodNavigationStack;