import { API_URL } from '@portal/config/env';

import getInstance from './instance';

const InstitutionalApi = {
  getReport: async () => {
    const instance = await getInstance();
    const { data } = await instance.get(`${API_URL}/institutional`);

    return data;
  },

  editInstitutional: async (params: models.InstitutionalUpdateRequest) => {
    const instance = await getInstance();
    const { data } = await instance.post(`${API_URL}/institutional`, params);

    return data;
  }
};

export default InstitutionalApi;