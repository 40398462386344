import { translate } from '~/services/i18n';

export const TYPE_EVENT: any = {
  NACIONAL: 1,
  SINODAL: 2,
};

export enum EventType {
  EVENT = 1,
  CAMPAIGN = 2,
  OFFERING = 3,
}

export const EventEnumArray: Array<{ value: number, name: string }> = [
  {
    value: EventType.EVENT,
    name: 'Evento',
  },
  {
    value: EventType.CAMPAIGN,
    name: 'Lembrete',
  },
];

export const getEventArray =  [
  { value: EventType.EVENT, i18n: 'ENUMERATORS.EVENT_TYPE.EVENT' },
  { value: EventType.CAMPAIGN, i18n: 'ENUMERATORS.EVENT_TYPE.REMIDER' },
  { value: EventType.OFFERING, i18n: 'ENUMERATORS.EVENT_TYPE.OFFERING' },
];

export const getEventTypeTitle = (value: number) => {
  let response: string = '--';

  if (value) {
    const found = getEventArray.find(o => o.value === value);

    if (found) {
      response = translate(found.i18n);
    }
  }

  return response;
};

export enum Scope {
  SYNODAL = 1,
  NATIONAL = 2,
  PAROCHIAL = 3,
};

export const getScope = [
  { value: Scope.SYNODAL, i18n: 'ENUMERATORS.SCOPE_TYPE.SYNODAL' },
  { value: Scope.NATIONAL, i18n: 'ENUMERATORS.SCOPE_TYPE.NATIONAL' },
  { value: Scope.PAROCHIAL, i18n: 'ENUMERATORS.SCOPE_TYPE.PAROCHIAL' },
];

export const getScopeTypeTitle = (value: number) => {
  let response: string = '--';

  if (value) {
    const found = getScope.find(o => o.value === value);

    if (found) {
      response = translate(found.i18n);
    }
  }

  return response;
};