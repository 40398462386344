import {
  NOTIFICATION_REPORT,
} from '~/actions/actionTypes';

const initialState: reducers.NotificationReducer = {
  list: null,
};

const notificationReducer = (
  state = initialState,
  action: any,
) => {
  switch (action.type) {
    case NOTIFICATION_REPORT:
      state = {
        ...state,
        list: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default notificationReducer;