import {
  USER_DETAIL,
  USER_LIST,
  USER_REPORT,
  USER_ME,
} from '~/actions/actionTypes';

const initialState: reducers.UserReducer = {
  detail: null,
  list: null,
  allUsers: [],
  me: null,
};

const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case USER_REPORT:
      state = {
        ...state,
        list: action.payload,
      };
      break;

    case USER_DETAIL:
      state = {
        ...state,
        detail: action.payload,
      };
      break;

    case USER_LIST:
      state = {
        ...state,
        allUsers: action.idList,
      };
      break;

    case USER_ME:
      state = {
        ...state,
        me: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default userReducer;
