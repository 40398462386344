import React from 'react';
import { Col, Row } from 'react-bootstrap';

import EcumenismImage from '~/assets/png/ecumenism.png';
import InstitutionalForm from '~/components/InstitutionalForm/InstitutionalForm';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import { translate } from '~/services/i18n';

const EcumenismReport: React.FC = () => {
  return (
    <div className="ecumenism">
      <Row>
        <Col lg={12}>
          <PanelContentHeader
            pageTitle={translate('PAGES.PANEL.ECUMENISM.REPORT.PAGE_TITLE')}
            pageDescription={translate(
              'PAGES.PANEL.ECUMENISM.REPORT.PAGE_DESCRIPTION'
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <InstitutionalForm
            title
            description
            sender='ecum'
          />
        </Col>
        <Col lg={6}>
          <img src={EcumenismImage} />
        </Col>
      </Row>
    </div>
  );
};

export default EcumenismReport;
