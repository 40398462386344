import NavigationService from '~/services/navigation';

import getInstance from './instance';

const API_URL = 'https://auth.mblabs.com.br/api';

const PasswordRecoveryApi = {
  sendVerification: async (email: string) => {
    const instance = getInstance();

    const { data } = await instance.post(`${API_URL}/user/password-recovery`, {
      email,
      sendTo: 'email',
    });

    // NavigationService.navigate('/alterar-senha');
    return data;
  },

  putRecovery: async (params: models.ResetPassword) => {
    const instance = getInstance();
    const { data } = await instance.put(
      `${API_URL}/user/password-recovery`,
      params
    );

    // NavigationService.navigate('/');
    return data;
  },
};

export default PasswordRecoveryApi;
