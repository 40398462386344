import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';

import PanelContentTopBar from '~/components/PanelContentTopBar/PanelContentTopBar';
import PanelSidebar from '~/components/PanelSidebar/PanelSidebar';
import { getRoutes, getStackPath } from '~/config/routes';
import ActivitiesNavigationStack from '~/pages/Activities/ActivitiesNavigationStack';
import DashboardNavigationStack from '~/pages/Dashboard/DashboardNavigationStack';
import InstitutionalNavigationStack from '~/pages/Institutional/InstitutionalNavigationStack';
import MySynodNavigationStack from '~/pages/MySynod/MySynodNavigationStack';
import NotFound from '~/pages/NotFound/NotFound';
import UserNavigationStack from '~/pages/User/UserNavigationStack';

const PanelNavigationStack: React.FC = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const loggedUser = useSelector(
    (state: reducers.rootReducer) => state.auth.me
  );

  return (
    <div className="panel-navigation-stack">
      <div className="panel-navigation-stack__sidebar">
        <PanelSidebar routes={getRoutes()} />
      </div>

      <div className="panel-navigation-stack__content">
        <PanelContentTopBar user={loggedUser} />

        <Switch>
          <Route path={getStackPath('DASHBOARD')}>
            <DashboardNavigationStack />
          </Route>
          <Route path={getStackPath('USER')}>
            <UserNavigationStack />
          </Route>

          <Route path={getStackPath('MEU_SINODO')}>
            <MySynodNavigationStack />
          </Route>

          <Route path={getStackPath('INSTITUCIONAL')}>
            <InstitutionalNavigationStack />
          </Route>

          <Route path={getStackPath('ACTIVITIES')}>
            <ActivitiesNavigationStack />
          </Route>

          <Route>
            <NotFound />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default PanelNavigationStack;
