import { LeftOutlined, PlusCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import PanelContentBreadcrumb from '~/components/PanelContentBreadcrumb/PanelContentBreadcrumb';

import { translate } from '~/services/i18n';
import * as MessageService from '~/services/message';

import * as SynodActions from '~/actions/synod';

import { getRouteStackPath } from '~/config/routes';

import { getPagetType } from '~/utils/page';

import { PAGE_TYPE } from '~/enum/pageType';

import { useReduxState } from '~/hooks/useReduxState';

const formInitialValues: models.Synod = {
  id: '',
  name: '',
};

const SynodDetails: React.FC = () => {
  const [form, setForm] = useState(formInitialValues);
  const [pageType] = useState(getPagetType());

  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const { pathname } = useLocation();

  const { synod } = useReduxState();

  const onFormChange = (key: string, val: any) => {
    setForm((prevState: models.Synod) => ({ ...prevState, [key]: val }));
  };

  useEffect(() => {
    if (synod && synod.detail) {
      setForm(synod.detail);
    } else {
      setForm(formInitialValues);
    }
  }, [synod.detail]);

  useEffect(() => {
    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(SynodActions.getDetail(params.id));
    } else {
      dispatch(SynodActions.cleanDetails());
    }
  }, [pathname, pageType]);

  const onFormSubmit = () => {

    if (!form.name) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_NAME');
    }

    const payload: any = {
      name: form.name,
    };

    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(SynodActions.update(synod?.detail?.id, payload));
    } else {
      dispatch(SynodActions.create(payload));
    }
  };

  return (
    <Container
      fluid
      className="details"
    >
      <Row>
        <Col>
          <PanelContentBreadcrumb
            items={[
              {
                title: translate('PAGES.PANEL.USER.SYNOD.REPORT.TITLE'),
                url: getRouteStackPath('USER', 'SYNOD_REPORT'),
                active: true,
              },
              {
                title: translate(`PAGES.PANEL.USER.SYNOD.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`),
                active: false,
              },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="details__title">
            <h3 className="details__title__text">
              {translate(`PAGES.PANEL.USER.SYNOD.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
            </h3>
            <p className="details__title__description">
              {translate(`PAGES.PANEL.USER.SYNOD.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
            </p>
          </div>
          <AdvancedForm onSubmit={onFormSubmit}>
            <div className="details__form">
              <Row>
                <Col md={4}>
                  <AdvancedInput
                    label={translate('PAGES.PANEL.USER.SYNOD.DETAILS.FORM.FIELDS.NAME.LABEL')}
                    value={form.name}
                    onChange={(val: string) => onFormChange('name', val)}
                  />
                </Col>
              </Row>

              <div className="details__form__actions">
                <Row>
                  <Col md={6}>
                    <Link to={getRouteStackPath('USER', 'SYNOD_REPORT')}>
                      <AdvancedButton
                        variant="text"
                        text={translate('SHARED.BACK')}
                        startIcon={<LeftOutlined />}
                      />
                    </Link>
                  </Col>
                  <Col
                    md={6}
                    className="text-right"
                  >
                    <AdvancedButton
                      type="submit"
                      text={translate('SHARED.SAVE')}
                      endIcon={<PlusCircleOutlined />}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </AdvancedForm>
        </Col>
      </Row>
    </Container>
  );
};

export default SynodDetails;
