import { Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { useEffect, useState } from 'react';

import { compressImages } from '@portal/utils/imageUtils';
import { getBase64 } from '@portal/utils/string';
import * as MessageService from '~/services/message';

interface IAdvancedUploader {
  disabled?: boolean;
  children?: JSX.Element | JSX.Element[];
  multiple?: boolean;
  onPreview?: (file: UploadFile<TemplateStringsArray>) => void;
  defaultImage?: any;
  className?: string;
  uploadClassName?: string;
  type?: 'image' | string;
  onChange: (file: any) => void;
  value?: any;
  hidePreview?: boolean;
  mp3?: boolean;
  fileList?: UploadFile<any>[];
}

const AdvancedUploader = ({
  disabled,
  children,
  multiple,
  onPreview,
  defaultImage,
  className,
  uploadClassName,
  type,
  onChange,
  value,
  hidePreview,
  mp3,
  fileList,
}: IAdvancedUploader) => {

  const onFileChange = async ({ file }: any) => {
    if (file) {
      const isJpgOrPng =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        (mp3 && file.type === 'audio/mpeg');

      if (!isJpgOrPng && type !== 'image') {
        return MessageService.error('COMPONENTS.ADVANCED_FILE_UPLOAD.ERROR');
      }
      if (type === 'image') {
        file.base64 = await compressImages([file]);
      } else {
        file.base64 = await getBase64(file);
      }

      file.extension = file.name.split('.').pop();
      onChange(file);
      toggleFilePreview(file ? file.base64 : value || null);
    } else {
      onChange(null);
    }
    setFile(file);
  };

  const toggleFilePreview = (file: any) => {
    setFilePreview(file);
  };

  const [filePreview, setFilePreview] = useState(false);
  const [file, setFile] = useState(null);

  return (
    <div className={className || 'advanced-file-upload'}>
      <Upload
        className={uploadClassName || 'advanced-file-upload__upload'}
        customRequest={onFileChange}
        disabled={disabled}
        fileList={fileList}
        multiple={multiple}
        onChange={onChange}
        onPreview={onPreview || (() => { })}
      >
        {(filePreview || defaultImage) && !hidePreview && (
          <div className="advanced-file-upload__preview">
            <img
              className="advanced-file-upload__preview__img"
              src={defaultImage ?? filePreview}
              alt="preview"
            />
          </div>
        )}
        {children}
      </Upload>
    </div>
  );
};

export default AdvancedUploader;
