import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getStackPath } from '@portal/config/routes';

import NewsNavigationStack from './News/NewsNavigationStack';
import NotificationsNavigationStack from './Notifications/NotificationsNavigationStack';
import PasswordsNavigationStack from './Passwords/PasswordsNavigationStack';
import ScheduleNavigationStack from './Schedule/ScheduleNavigationStack';

const ActivitiesNavigationStack = () => {
  return (
    <Switch>
      <Route path={getStackPath('ACTIVITIES')}>
        <ScheduleNavigationStack />
        <NewsNavigationStack />
        <PasswordsNavigationStack />
        <NotificationsNavigationStack />
      </Route>
    </Switch>
  );
};

export default ActivitiesNavigationStack;
