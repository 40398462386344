import getInstance from './instance';

const BASE_URL: string = '/parish';

const ParishRepository = {
  getReport: async (params: advancedFilterModels.IParishAdvancedFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get(`${BASE_URL}`, { params });

    return data;
  },

  getAll: async () => {
    const instance = await getInstance();
    const { data } = await instance.get(`${BASE_URL}/all`, { params: { limit: 100 } });

    return data;
  },

  getDetail: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.get(`${BASE_URL}/${id}`);

    return data;
  },

  create: async (params: models.Parish) => {
    const instance = await getInstance();
    const { data } = await instance.post(BASE_URL, params);

    return data;
  },

  remove: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.delete(`${BASE_URL}/${id}`);

    return data;
  },

  update: async (id?: string, params?: any) => {
    const instance = await getInstance();
    const { data } = await instance.put(`${BASE_URL}/${id}`, params);

    return data;
  },
};

export default ParishRepository;
