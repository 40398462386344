import { INotificationsAdvancedFilter } from '@portal/models/advanced-filters';
import { translate } from '@portal/services/i18n';
import NotificationRequests from '~/repositories/notification';
import centralNavigationService from '~/services/navigation';
import * as MessageService from '~/services/message';

import { NOTIFICATION_REPORT } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const getNotifications = (
  params: INotificationsAdvancedFilter
) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.Notification> = await NotificationRequests.getNotifications(
      params
    );
    dispatch({
      payload,
      type: NOTIFICATION_REPORT,
    });
  } catch (err) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const addNotification = (payload: models.NotificationApi) => async (
  dispatch: any
) => {
  dispatch(increaseLoading());
  try {
    await NotificationRequests.addNotification(payload);

    MessageService.success(
      translate('PAGES.PANEL.NOTIFICATIONS.MESSAGES.SUCCESS')
    );
    await centralNavigationService.back();
  } catch (err) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};
