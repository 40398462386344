import { API_URL } from '@portal/config/env';

import getInstance from './instance';

const BASE_URL: string = '/user';

const UserApi = {
  me: async () => {
    const instance = await getInstance();
    const { data } = await instance.get(`${BASE_URL}/me`);

    return data;
  },

  getReport: async (params: advancedFilterModels.IUserAdvancedFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get(BASE_URL, { params });

    return data;
  },

  getDetail: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.get(`${BASE_URL}/${id}`);

    return data;
  },

  create: async (params: models.User) => {
    const instance = await getInstance();
    const { data } = await instance.post(BASE_URL, params);

    return data;
  },

  remove: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.delete(`${BASE_URL}/${id}`);

    return data;
  },

  update: async (id?: string, params?: any) => {
    const instance = await getInstance();
    const { data } = await instance.put(`${BASE_URL}/${id}`, params);

    return data;
  },
};

export default UserApi;
