import { PlusCircleOutlined } from '@ant-design/icons';
import { CellParams } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import * as NewsActions from '~/actions/news';
import { TYPE_EVENT } from '@portal/enum/typeEvent';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import DataTable from '~/components/DataTable/DataTable';
import DataTableActions from '~/components/DataTableActions/DataTableActions';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import PanelContentSearchBar from '~/components/PanelContentSearchBar/PanelContentSearchBar';
import { translate } from '~/services/i18n';
import NavigationService from '~/services/navigation';
import { useReduxState } from '@portal/hooks/useReduxState';
import { getRouteStackPath } from '@portal/config/routes';

const initialValues: advancedFilterModels.INewsAdvancedFilter = {
  title: '',
  description: '',
  fileUrl: '',
  date: '',
  type: TYPE_EVENT.SINODAL,
  pageSize: 10,
  page: 1,
  orderBy: 'createdAt',
  sort: 'desc',
  offset: 0,
  limit: 10,
};

const NewsReport: React.FC = () => {
  const dispatch = useDispatch();
  const [advancedFilters, setAdvancedFilters] = useState(initialValues);
  const { news } = useReduxState();


  useEffect(() => {
    const filter = NavigationService.getQuery();

    onSearch({
      ...advancedFilters,
      ...filter,
    });
  }, []);

  const onSearch = (filters: advancedFilterModels.INewsAdvancedFilter) => {
    dispatch(NewsActions.getReport(filters));
  };

  const onRemove = async (id: string) => {
    await dispatch(NewsActions.remove(id));

    const filter = NavigationService.getQuery();
    onSearch({
      ...advancedFilters,
      ...filter,
    });
  }

  return (
    <div className="report">
      <PanelContentSearchBar
        onFormSubmit={(form: any) => onSearch({
          ...advancedFilters,
          title: form.search,
        })}
      />

      <div className="report__header">
        <Row>
          <Col lg={9}>
            <PanelContentHeader
              pageTitle={translate('PAGES.PANEL.NEWS.REPORT.PAGE_TITLE')}
              pageDescription={translate('PAGES.PANEL.NEWS.REPORT.PAGE_DESCRIPTION')}
            />
          </Col>
          <Col lg={3} className="text-right">
            <Link to={`${getRouteStackPath('ACTIVITIES', 'NEWS_DETAILS')}/${translate('SHARED.ADD_ID')}`}>
              <AdvancedButton
                className="news__header advanced-button-modified"
                text={translate('PAGES.PANEL.NEWS.REPORT.BUTTON_LINK')}
                endIcon={<PlusCircleOutlined />}
              />
            </Link>
          </Col>
        </Row>
      </div>

      <div className="report__table">
        <Row>
          <Col>
            <div className="report__table__inner">
              <DataTable
                rows={news?.list?.rows || []}
                rowCount={news?.list?.count || 0}
                columns={[
                  {
                    field: translate('PAGES.PANEL.NEWS.FIELDS.ID.KEY'),
                    headerName: translate('PAGES.PANEL.NEWS.FIELDS.ID.TITLE'),
                    flex: 1,
                    sortable: false,
                    hide: true,
                  },
                  {
                    field: translate('PAGES.PANEL.NEWS.FIELDS.TITLE.KEY'),
                    headerName: translate('PAGES.PANEL.NEWS.FIELDS.TITLE.TITLE'),
                    flex: 1,
                    valueFormatter: (params) => params.row?.ad?.title,
                  },
                  {
                    field: translate('PAGES.PANEL.NEWS.FIELDS.CREATED_AT.KEY'),
                    headerName: translate('PAGES.PANEL.NEWS.FIELDS.CREATED_AT.TITLE'),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return (
                        <>
                          {DateTime.fromISO(o.value as string).toLocaleString(
                            DateTime.DATETIME_SHORT
                          )}
                        </>
                      );
                    },
                  },
                  {
                    align: 'center',
                    field: translate('PAGES.PANEL.NEWS.FIELDS.ACTIONS.KEY'),
                    headerAlign: 'center',
                    headerName: translate('PAGES.PANEL.NEWS.FIELDS.ACTIONS.TITLE'),
                    renderCell: (o: CellParams) => {
                      return (
                        <DataTableActions
                          row={o.row}
                          basePath={getRouteStackPath('ACTIVITIES', 'NEWS_DETAILS')}
                          onRemove={onRemove}
                        />
                      );
                    },
                  },
                ]}
                pageSize={advancedFilters.pageSize}
                page={advancedFilters.page}
                sort={advancedFilters.sort}
                orderBy={advancedFilters.orderBy}
                onChange={(filters) => {
                  const searchFilters = {
                    ...advancedFilters,
                    ...filters,
                  };
                  setAdvancedFilters(searchFilters);
                  onSearch(searchFilters);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NewsReport;
