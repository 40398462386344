/* tslint:disable:object-literal-sort-keys */
const ptBr = {
  APPLICATION: {
    CURRENCY: 'BRL',
    ERRORS: {
      GENERIC: 'Parece que algo não saiu bem. Tente novamente mais tarde.',
      USER_ALREADY_EXISTS: 'Já existe um usuário com o e-mail cadastrado.',
      INVALID_KEY: 'Key do i18n inválida ou inexistente.',

      EMPTY_NAME: 'O campo "nome" é requerido.',
      EMPTY_CELLPHONE: 'O campo "telefone" é requerido.',
      EMPTY_EMAIL: 'O campo "e-mail" é requerido.',
      EMPTY_PASSWORD: 'O campo "senha" é requerido.',
      MISMATCH_PASSWORD: 'As senhas não são iguais.',

      EMPTY_SYNOD_ID: 'O campo "sínodo" é requerido.',
      EMPTY_ZIPCODE: 'O campo "CEP" é requerido.',
      EMPTY_STREET: 'O campo "rua" é requerido.',
      EMPTY_NUMBER: 'O campo "número" é requerido.',
      EMPTY_NEIGHBORHOOD: 'O campo "bairro" é requerido.',
      EMPTY_CITY: 'O campo "cidade" é requerido.',
      EMPTY_STATE: 'O campo "estado" é requerido.',

      INSTANCE: {
        REFRESH_TOKEN: 'Token expirado. Faça Login novamente!',
        LOGIN: 'Faça Login novamente!',
      },
    },
    LANG: 'pt-BR',
  },
  COMPONENTS: {
    ADVANCED_FILTER: {
      FILTER: 'Filtrar',
      CLEAN: 'limpar',
    },
    SEARCH_BAR: {
      SEARCH: {
        LABEL: 'O que deseja procurar?',
      },
    },
    ADVANCED_FILE_UPLOAD: {
      ERROR: 'Por favor insira uma imagem no formato png ou jpg',
    },
    DATA_TABLE_ACTIONS: {
      EDIT: {
        LABEL: 'Editar',
      },
      VIEW: {
        LABEL: 'Visualizar',
      },
      DELETE: {
        LABEL: 'Deletar',
      },
      PRINT: {
        LABEL: 'Imprimir',
      },
      OPEN: {
        LABEL: 'Abrir',
      },
      HISTORY: {
        LABEL: 'Histórico',
      },
    },
    PANEL_CONTENT_TOP_BAR: {
      PAGES: {
        DASHBOARD: {
          TITLE: 'Dashboard',
          LOGOUT: 'Sair',
        },
        MARKETING: {
          TITLE: 'Marketing',
        },
        TREASURY: {
          TITLE: 'Tesouraria',
        },
      },
    },
  },
  PAGES: {
    AUTH: {
      LOGIN: {
        URL: '/',
        PAGE_TITLE: 'Entrar',
        MESSAGES: {
          LOGOUT: 'Você saiu do sistema.',
          WELCOME: 'Bem vindo(a) novamente.',
          INVALID: 'Email e/ou senha incorretos.',
        },
        ERRORS: {
          INVALID_USERNAME: 'Insira um e-mail válido.',
          INVALID_PASSWORD: 'Insira uma senha segura.',
        },
        FORM: {
          TITLE: 'ENTRAR',
          MESSAGE:
            'Utilize o seu e-mail e senha de administrador para entrar no painel administrativo.',
          EMAIL: {
            LABEL: 'E-mail',
            PLACEHOLDER: 'Seu e-mail válido',
          },
          PASSWORD: {
            LABEL: 'Senha',
            PLACEHOLDER: 'Seu senha segura',
          },
          BUTTON: {
            TEXT: 'Entrar',
          },
          BOTTOM_MESSAGE: {
            TEXT: 'Esqueceu sua senha?',
            TEXT_LINK: 'Clique aqui',
          },
        },
      },
      PASSWORD_RECOVERY: {
        URL: '/recuperar-senha',
        PAGE_TITLE: 'Recuperar senha',
        MESSAGES: {
          WELCOME: 'Bem vindo(a) novamente.',
          LOGOUT: 'Você saiu do sistema.',
          EMAIL: 'Verifique seu email',
          RECOVERY_SUCCESS: 'Senha alterada com sucesso',
        },
        FORM: {
          TITLE: 'RECUPERAR SENHA',
          MESSAGE: 'Envie um email de confirmação para trocar sua senha',

          EMAIL: {
            LABEL: 'E-mail',
            PLACEHOLDER: 'Seu e-mail válido',
          },
          BUTTON: {
            TEXT: 'Enviar e-mail',
          },
          BOTTOM_MESSAGE: {
            TEXT: 'Para voltar para o login,',
            TEXT_LINK: 'Clique aqui',
          },
          LABELS: {
            EMAIL: 'Email',
            TOKEN: 'Código de recuperação',
            PASSWORD: 'Nova senha',
            CHANGE_PASSWORD: 'ALTERAR SENHA',
          },
        },
      },
    },
    PANEL: {
      DASHBOARD: {
        SIDEBAR_TITLE: 'Dashboard',
        DETAILS: {
          WELCOME: 'Seja bem vindo(a) novamente ',
          WELCOME_NAME: 'Luan!',
          TITLE: 'Informações gerais',
          PAGE_DESCRIPTION: 'Visualize alguns dos principais indicadores',
          CARDS: {
            ONE: {
              NUMBER: '800',
              DESCRIPTION: 'Sínodos',
            },
            TWO: {
              NUMBER: '150',
              DESCRIPTION: 'Paróquias',
            },
            THREE: {
              NUMBER: '350',
              DESCRIPTION: 'Comunidades',
            },
          },
          TABLE: {
            TITLE: 'Sínodos',
            DESCRIPTION: 'Últimos Sínodos cadastrados',
            FIELDS: {
              DATE: {
                TITLE: 'DATA REGISTRO',
              },
              NAME: {
                TITLE: 'NOME',
              },
              CITY: {
                TITLE: 'CIDADE',
              },
              TOTAL: {
                TITLE: 'TOTAL',
              },
              ACTIONS: {
                TITLE: 'AÇÕES',
              },
            },
          },
          BARS: {
            TITLE: 'Empréstimos',
            DESCRIPTION:
              'Visualize alguns dos principais indicadores da financeira do seu banco',
            ITEMS: {
              ONE: {
                NUMBER: '1253',
                DESCRIPTION: 'Simulações de limite',
                PERCENTAGE: '75',
              },
              TWO: {
                NUMBER: '651',
                DESCRIPTION: 'Contratos efetuados',
                PERCENTAGE: '50',
              },

              THREE: {
                NUMBER: '257',
                DESCRIPTION: 'Crédito negado',
                PERCENTAGE: '25',
              },
            },
          },
        },
      },

      PANEL_CONTENT_ADD: {
        NOME: 'name',
        PARISH: 'paroquia',
        COMMUNITY: 'comunidade',
      },

      USER: {
        SIDEBAR_TITLE: 'Usuários',
        ADMIN: {
          MESSAGES: {
            SUCCESS_ADD_MESSAGE: 'Administrador adicionado com sucesso.',
            SUCCESS_EDIT_MESSAGE: 'Administrador editado com sucesso.',
            SUCCESS_REMOVE_MESSAGE: 'Administrador removido com sucesso.',
          },
          REPORT: {
            TITLE: 'Administradores',
            DESCRIPTION: 'Administradores cadastrados na plataforma',
            FIELDS: {
              ID: {
                KEY: 'id',
                TITLE: 'id',
              },
              NAME: {
                KEY: 'name',
                TITLE: 'Nome',
              },
              EMAIL: {
                KEY: 'email',
                TITLE: 'E-mail',
              },
              PROFILE_TYPE: {
                KEY: 'profileType',
                TITLE: 'Perfil',
              },
              CREATED_AT: {
                KEY: 'createdAt',
                TITLE: 'Criação',
              },
              ACTIONS: {
                KEY: 'actions',
                TITLE: 'Ações',
              },
            },
          },

          DETAILS: {
            TITLE_ADD: 'Adicionar administrador',
            TITLE_EDIT: 'Editar administrador',
            PAGE_DESCRIPTION_ADD:
              'Preencha os campos abaixo para adicionar um administrador',
            PAGE_DESCRIPTION_EDIT:
              'Preencha os campos abaixo para editar um administrador',
            SUCCESS_ADD_MESSAGE: 'Administrador adicionado com sucesso.',
            SUCCESS_EDIT_MESSAGE: 'Administrador editado com sucesso.',
            SUCCESS_REMOVE_MESSAGE: 'Administrador removido com sucesso.',
            FORM: {
              FIELDS: {
                NAME: {
                  LABEL: 'Nome completo',
                },
                EMAIL: {
                  LABEL: 'E-mail',
                },
                CELLPHONE: {
                  LABEL: 'Telefone',
                },
                PROFILE_TYPE: {
                  LABEL: 'Tipo de perfil',
                },
                MUST_CHANGE_PASSWORD: {
                  LABEL: 'Alterar a senha?',
                },
                SELECT_SYNOD: {
                  LABEL: 'Selecionar sínodo',
                },
                PASSWORD: {
                  LABEL: 'Senha',
                },
                CONFIRM_PASSWORD: {
                  LABEL: 'Confirmar senha',
                },
              },
            },
          },
        },

        USER: {
          MESSAGES: {
            SUCCESS_ADD_MESSAGE: 'Usuário adicionado com sucesso.',
            SUCCESS_EDIT_MESSAGE: 'Usuário editado com sucesso.',
            SUCCESS_REMOVE_MESSAGE: 'Usuário removido com sucesso.',
          },
          REPORT: {
            TITLE: 'Usuários',
            DESCRIPTION: 'Usuários cadastrados na plataforma',
            FIELDS: {
              ID: {
                KEY: 'id',
                TITLE: 'id',
              },
              NAME: {
                KEY: 'name',
                TITLE: 'Nome',
              },
              EMAIL: {
                KEY: 'email',
                TITLE: 'E-mail',
              },
              CREATED_AT: {
                KEY: 'createdAt',
                TITLE: 'Criação',
              },
              ACTIONS: {
                KEY: 'actions',
                TITLE: 'Ações',
              },
            },
          },

          DETAILS: {
            TITLE_ADD: 'Adicionar usuário',
            TITLE_EDIT: 'Editar usuário',
            PAGE_DESCRIPTION_ADD:
              'Preencha os campos abaixo para adicionar um usuário',
            PAGE_DESCRIPTION_EDIT:
              'Preencha os campos abaixo para editar um usuário',
            FORM: {
              FIELDS: {
                NAME: {
                  LABEL: 'Nome completo',
                },
                EMAIL: {
                  LABEL: 'E-mail',
                },
                CELLPHONE: {
                  LABEL: 'Telefone',
                },
                MUST_CHANGE_PASSWORD: {
                  LABEL: 'Alterar a senha?',
                },
                PASSWORD: {
                  LABEL: 'Senha',
                },
                CONFIRM_PASSWORD: {
                  LABEL: 'Confirmar senha',
                },
              },
            },
          },
        },

        SYNOD: {
          MESSAGES: {
            SUCCESS_ADD_MESSAGE: 'Sínodo adicionado com sucesso.',
            SUCCESS_EDIT_MESSAGE: 'Sínodo editado com sucesso.',
            SUCCESS_REMOVE_MESSAGE: 'Sínodo removido com sucesso.',
          },
          REPORT: {
            TITLE: 'Sínodos',
            DESCRIPTION: 'Sínodos cadastrados na plataforma',
            FIELDS: {
              ID: {
                KEY: 'id',
                TITLE: 'id',
              },
              NAME: {
                KEY: 'name',
                TITLE: 'Nome',
              },
              CREATED_AT: {
                KEY: 'createdAt',
                TITLE: 'Criação',
              },
              ACTIONS: {
                KEY: 'actions',
                TITLE: 'Ações',
              },
            },
          },

          DETAILS: {
            TITLE_ADD: 'Adicionar sínodo',
            TITLE_EDIT: 'Editar sínodo',
            PAGE_DESCRIPTION_ADD:
              'Preencha os campos abaixo para adicionar um sínodo',
            PAGE_DESCRIPTION_EDIT:
              'Preencha os campos abaixo para editar um sínodo',
            FORM: {
              FIELDS: {
                NAME: {
                  LABEL: 'Nome',
                },
              },
            },
          },
        },

        PARISH: {
          MESSAGES: {
            SUCCESS_ADD_MESSAGE: 'Paróquia adicionada com sucesso.',
            SUCCESS_EDIT_MESSAGE: 'Paróquia editada com sucesso.',
            SUCCESS_REMOVE_MESSAGE: 'Paróquia removida com sucesso.',
          },
          REPORT: {
            TITLE: 'Paróquias',
            DESCRIPTION: 'Paróquias cadastradas na plataforma',
            FIELDS: {
              ID: {
                KEY: 'id',
                TITLE: 'id',
              },
              NAME: {
                KEY: 'name',
                TITLE: 'Nome',
              },
              CREATED_AT: {
                KEY: 'createdAt',
                TITLE: 'Criação',
              },
              CITY: {
                KEY: 'address',
                TITLE: 'Cidade',
              },
              ACTIONS: {
                KEY: 'actions',
                TITLE: 'Ações',
              },
            },
          },

          DETAILS: {
            TITLE_ADD: 'Adicionar paróquia',
            TITLE_EDIT: 'Editar paróquia',
            PAGE_DESCRIPTION_ADD:
              'Preencha os campos abaixo para adicionar uma paróquia',
            PAGE_DESCRIPTION_EDIT:
              'Preencha os campos abaixo para editar uma paróquia',
            FORM: {
              FIELDS: {
                NAME: {
                  LABEL: 'Nome *',
                },
                SYNOD: {
                  LABEL: 'Sínodo *',
                },
                ZIPCODE: {
                  LABEL: 'CEP *',
                },
                STREET: {
                  LABEL: 'Rua *',
                },
                NUMBER: {
                  LABEL: 'Número *',
                },
                NEIGHBORHOOD: {
                  LABEL: 'Bairro *',
                },
                CITY: {
                  LABEL: 'Cidade *',
                },
                STATE: {
                  LABEL: 'Estado *',
                },
                COMPLEMENT: {
                  LABEL: 'Complemento',
                },
                LONGITUDE: {
                  LABEL: 'Longitude',
                },
                LATITUDE: {
                  LABEL: 'Latitude',
                },
              },
            },
          },
        },

        LEADERS: {
          MESSAGES: {
            SUCCESS_ADD_MESSAGE: 'Liderança adicionada com sucesso.',
            SUCCESS_EDIT_MESSAGE: 'Liderança editada com sucesso.',
            SUCCESS_REMOVE_MESSAGE: 'Liderança removida com sucesso.',
          },
          REPORT: {
            TITLE: 'Lideranças',
            DESCRIPTION: 'Lideranças cadastradas na plataforma',
            FIELDS: {
              ID: {
                KEY: 'id',
                TITLE: 'id',
              },
              NAME: {
                KEY: 'name',
                TITLE: 'Nome',
              },
              CREATED_AT: {
                KEY: 'createdAt',
                TITLE: 'Criação',
              },
              ACTIONS: {
                KEY: 'actions',
                TITLE: 'Ações',
              },
            },
          },

          DETAILS: {
            TITLE_ADD: 'Adicionar liderança',
            TITLE_EDIT: 'Editar liderança',
            PAGE_DESCRIPTION_ADD:
              'Preencha os campos abaixo para adicionar uma liderança',
            PAGE_DESCRIPTION_EDIT:
              'Preencha os campos abaixo para editar uma liderança',
            FORM: {
              FIELDS: {
                NAME: {
                  LABEL: 'Nome',
                },
                LEADER_ROLE: {
                  LABEL: 'Perfil',
                },
                SYNOD_ID: {
                  LABEL: 'Sínodo',
                },
              },
            },
          },
        },

        COMMUNITY: {
          MESSAGES: {
            SUCCESS_ADD_MESSAGE: 'Comunidade adicionada com sucesso.',
            SUCCESS_EDIT_MESSAGE: 'Comunidade editada com sucesso.',
            SUCCESS_REMOVE_MESSAGE: 'Comunidade removido com sucesso.',
          },
          REPORT: {
            TITLE: 'Comunidades',
            DESCRIPTION: 'Comunidades cadastradas na plataforma',
            FIELDS: {
              ID: {
                KEY: 'id',
                TITLE: 'id',
              },
              NAME: {
                KEY: 'name',
                TITLE: 'Nome',
              },
              CREATED_AT: {
                KEY: 'createdAt',
                TITLE: 'Criação',
              },
              ACTIONS: {
                KEY: 'actions',
                TITLE: 'Ações',
              },
            },
          },

          DETAILS: {
            TITLE_ADD: 'Adicionar Comunidade',
            TITLE_EDIT: 'Editar Comunidade',
            PAGE_DESCRIPTION_ADD:
              'Preencha os campos abaixo para adicionar um Comunidade',
            PAGE_DESCRIPTION_EDIT:
              'Preencha os campos abaixo para editar um Comunidade',
            FORM: {
              FIELDS: {
                NAME: {
                  LABEL: 'Nome *',
                },
                PARISH: {
                  LABEL: 'Paróquia *',
                },
                ZIPCODE: {
                  LABEL: 'CEP *',
                },
                STREET: {
                  LABEL: 'Rua *',
                },
                NUMBER: {
                  LABEL: 'Número *',
                },
                NEIGHBORHOOD: {
                  LABEL: 'Bairro *',
                },
                CITY: {
                  LABEL: 'Cidade *',
                },
                STATE: {
                  LABEL: 'Estado *',
                },
                COMPLEMENT: {
                  LABEL: 'Complemento',
                },
                LONGITUDE: {
                  LABEL: 'Longitude',
                },
                LATITUDE: {
                  LABEL: 'Latitude',
                },
              },
            },
          },
        },
      },

      NEW_ACTIVITIES: {
        SIDEBAR_TITLE: 'ATIVIDADES',
        SCHEDULE: {
          MESSAGES: {
            SUCCESS_ADD_MESSAGE: 'Evento adicionado com sucesso.',
            SUCCESS_EDIT_MESSAGE: 'Evento editado com sucesso.',
            SUCCESS_REMOVE_MESSAGE: 'Evento removido com sucesso.',
          },
          REPORT: {
            TITLE: 'Agenda & Eventos',
            DESCRIPTION: 'Agendas e eventos cadastrados na plataforma',
            FIELDS: {
              ID: {
                KEY: 'id',
                TITLE: 'Id',
              },
              TITLE: {
                KEY: 'title',
                TITLE: 'Título',
              },
              DESCRIPTION: {
                KEY: 'description',
                TITLE: 'Descrição',
              },
              SCOPE: {
                KEY: 'scope',
                TITLE: 'Perfil',
              },
              TYPE: {
                KEY: 'type',
                TITLE: 'Tipo do evento',
              },
              START_EVENT: {
                KEY: 'startsAtDate',
                TITLE: 'Data do evento',
              },
              PHONE: {
                KEY: 'phone',
                TITLE: 'Telefone',
              },
              STREET: {
                KEY: 'address.street',
                TITLE: 'Rua',
              },
              CITY: {
                KEY: 'address.city',
                TITLE: 'Cidade',
              },
              STATE: {
                KEY: 'address.state',
                TITLE: 'Estado',
              },
              NUMBER: {
                KEY: 'address.number',
                TITLE: 'Número',
              },
              ZIPCODE: {
                KEY: 'address.zipcode',
                TITLE: 'CEP',
              },
            },
          },

          DETAILS: {
            TITLE_ADD: 'Adicionar evento',
            TITLE_EDIT: 'Editar evento',
            PAGE_DESCRIPTION_ADD:
              'Preencha os campos abaixo para adicionar um evento',
            PAGE_DESCRIPTION_EDIT:
              'Preencha os campos abaixo para editar um evento',
            SUCCESS_ADD_MESSAGE: 'Evento adicionado com sucesso.',
            SUCCESS_EDIT_MESSAGE: 'Evento editado com sucesso.',
            SUCCESS_REMOVE_MESSAGE: 'Evento removido com sucesso.',
            PLACEHOLDER_DATE: '11/11/2021',
            PLACEHOLDER_TIME: '14:00',
            ERRORS: {
              IMAGE: 'Insira a imagem do evento',
              DATE: 'Insira as datas do evento!',
              EVENT: 'Insira sobre o evento corretamente!',
              DESCRIPTION: 'Insira a descrição!',
            },
          },
        },
      },

      INSTITUTIONAL: {
        TITLE: 'USUÁRIOS',
        FORM: {
          URL: 'Insira a URL do vídeo a ser postado no aplicativo',
          TITLE: 'Insira o título da mensagem',
          DESCRIPTION: 'Insira a mensagem',
          OTHER_INFO: 'Outras informações',
          PARTICIPATE_TITLE: 'Titulo para como participar',
          PARTICIPATE: 'Como participar',
          HISTORY_TITLE: 'Título para nossa história',
          HISTORY: 'Nossa história',
          MISSION_TITLE: 'Título para nossa missão',
          MISSION: 'Nossa missão',
          BUTTON: 'Salvar',
        },
      },
      IECLB: {
        ROUTE: {
          NAME: 'INSTITUCIONAL',
          REPORT: 'IECLB',
          DETAILS: 'DETALHES',
        },
        TITLE: 'IECLB',
        REPORT: {
          TITLE: 'Mensagens',
          PAGE_TITLE: 'Mensagem IECLB',
          PAGE_DESCRIPTION:
            'Preencha com os campos com as mensagens indicadas:',
        },
      },
      ORGANIZATION: {
        ROUTE: {
          NAME: 'INSTITUCIONAL',
          REPORT: 'ORGANIZATION',
          DETAILS: 'DETALHES',
        },
        TITLE: 'Organização',
        REPORT: {
          TITLE: 'Mensagens',
          PAGE_TITLE: 'Mensagem para organização',
          PAGE_DESCRIPTION:
            'Preencha com os campos com as mensagens indicadas:',
        },
      },
      ECUMENISM: {
        ROUTE: {
          NAME: 'INSTITUCIONAL',
          REPORT: 'ECUMENISM',
          DETAILS: 'DETALHES',
        },
        TITLE: 'Ecumenismo',
        REPORT: {
          TITLE: 'Mensagens',
          PAGE_TITLE: 'Mensagem para ecumenismo',
          PAGE_DESCRIPTION:
            'Preencha com os campos com as mensagens indicadas:',
        },
      },
      INSTITUTION: {
        ROUTE: {
          NAME: 'INSTITUCIONAL',
          REPORT: 'INSTITUTION',
          DETAILS: 'DETALHES',
        },
        TITLE: 'Instituições',
        REPORT: {
          TITLE: 'Mensagens',
          PAGE_TITLE: 'Mensagem para instituições',
          PAGE_DESCRIPTION:
            'Preencha com os campos com as mensagens indicadas:',
        },
      },

      ACTIVITIES: {
        TITLE: 'ATIVIDADES',
        SIDEBAR_TITLE: 'ATIVIDADES',
        FORM: {
          URL: 'Insira a URL do vídeo a ser postado no aplicativo',
          TITLE: 'Insira o título da mensagem',
          DESCRIPTION: 'Insira a mensagem',
          BUTTON: 'Salvar',
        },
      },
      SCHEDULE: {
        ROUTE: {
          NAME: 'ATIVIDADES',
          REPORT: 'SCHEDULE',
          DETAILS: 'DETALHES',
          URL_ADD: '/atividades/agenda/detalhes/:id?',
        },
        TITLE: 'Agenda & eventos',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Agenda & eventos',
          PAGE_TITLE: 'Agenda & eventos',
          PAGE_DESCRIPTION:
            'Preencha com os campos com as mensagens indicadas:',
          ADVANCED_FILTER: {
            TITLE: 'Título',
            DATE_BEGIN: 'Data de início',
            DATE_END: 'Data final',
            CITY: 'Cidade',
          },
          TABLE: {
            FIELD_TITLE: 'title',
            TITLE: 'Título',
            FIELD_DATA_BEGIN: 'dataBegin',
            DATA_BEGIN: 'Data inicial',
            FIELD_DATA_END: 'dataEnd',
            DATA_END: 'Data final',
            FIELD_TYPE: 'type',
            TYPE: 'Tipo de Evento',
            FIELD_CITY: 'city',
            CITY: 'Cidade',
          },
        },
      },
      SCHEDULE_DETAILS: {
        ROUTE: {
          NAME: 'ATIVIDADES',
          REPORT: 'SCHEDULE_DETAILS',
          DETAILS: 'DETALHES',
        },
        TITLE: 'Agenda & eventos',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Agenda & eventos',
          PAGE_TITLE: 'Agenda & eventos',
          PAGE_DESCRIPTION:
            'cadatre o seu Evento abaixo preenchendo as informações!',
          ADVANCED_FILTER: {
            TITLE: 'Título',
            DATE_BEGIN: 'Data de início:',
            DATE_END: 'Data final:',
            CITY: 'Cidade',
          },
          ADD: {
            LABELS: {
              UPLOAD: 'Adicione a imagem do evento',
              DATE_BEGIN: 'Data de início:',
              DATE_END: 'Data final:',
              TITLE_TIME: 'Horário do Evento:',
              TYPE_EVENT: 'Sobre o evento',
              EVENT: 'Tipo de evento',
              ADD_SYNOD: 'Deseja adicionar para um sínodo?',
              SYNOD: 'Sínodo',
              NAME_EVENT: 'Nome do evento',
              TITLE_DATE: 'Data do evento:',
              TIME_BEGIN: 'Hora de início:',
              TIME_END: 'Hora Final:',
              TITLE: 'Título',
              DESCRIPTION: 'Descrição',
              ADDRESS: 'Localização',
              STREET: 'Rua',
              NUMBER: 'Número',
              NEIGHBORHOOD: 'Bairro',
              ZIPCODE: 'CEP',
              CITY: 'Cidade',
              STATE: 'Estado',
              COMPLEMENT: 'Complemento',
              LAT: 'Latitude',
              LONG: 'Longitude',
              MISCINFO: 'Outras informações',
              ADD_SUPORT_INFO: 'Deseja adicionar informações de contato?',
              MEDIA_URL: 'Link do Youtube',
              EMAIL: 'E-mail',
              PHONE: 'Telefone',
              BUTTON: 'Salvar',
              BUTTON_UPLOAD: 'Adicionar imagem',
              BUTTON_UPLOAD_MULTIPLE: 'Adicionar imagens',
            },
            ERRORS: {
              MEDIA_URL: 'Insira um vídeo do youtube válido',
              INVALID_DATE: 'Data inválida',
              INVALID_HOUR: 'Horário inválido',
            },
          },
        },
      },
      NEWS: {
        ROUTE: {
          NAME: 'ATIVIDADES',
          REPORT: 'NEWS',
          DETAILS: 'DETALHES',
          URL_ADD: '/atividades/noticia/detalhes/:id?',
        },
        MESSAGES: {
          SUCCESS_ADD_MESSAGE: 'Notícia adicionada com sucesso.',
          SUCCESS_EDIT_MESSAGE: 'Notícia editada com sucesso.',
          SUCCESS_DELETE_MESSAGE: 'Notícia deletada com sucesso.',
        },
        TITLE: 'Notícias',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Notícias',
          PAGE_TITLE: 'Notícias',
          PAGE_DESCRIPTION: 'Notícias cadastradas na plataforma',
          ADVANCED_FILTER: {
            TITLE: 'Título',
            DATE: 'Data',
          },
          ADD: {
            LABELS: {
              TITLE: 'Título',
              DESCRIPTION: 'Descrição',
              FILE_URL: 'Faça o upload de um arquivo',
              DATE: 'Data',
              BUTTON: 'Salvar',
            },
          },
          TABLE: {
            FIELD_TITLE: 'title',
            TITLE: 'Título',
            FIELD_DATA: 'data',
            DATA: 'Data',
            FIELD_TYPE: 'type',
            TYPE: 'Tipo de Evento',
          },
        },
        DETAILS: {
          TITLE_ADD: 'Adicionar notícia',
          TITLE_EDIT: 'Editar notícia',
          PAGE_DESCRIPTION_ADD:
            'Preencha os campos abaixo para adicionar uma notícia',
          PAGE_DESCRIPTION_EDIT:
            'Preencha os campos abaixo para editar a notícia',
        },
        FIELDS: {
          ID: {
            KEY: 'id',
            TITLE: 'ID',
          },
          TITLE: {
            KEY: 'ad.title',
            TITLE: 'Título',
          },
          CREATED_AT: {
            KEY: 'createdAt',
            TITLE: 'Data',
          },
          ACTIONS: {
            KEY: 'actions',
            TITLE: 'Ações',
          },
        },
      },

      NEWS_ADD: {
        ROUTE: {
          NAME: 'ATIVIDADES',
          REPORT: 'NEWS_ADD',
          DETAILS: 'DETALHES',
        },
        TITLE: 'Notícias',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Notícias',
          PAGE_TITLE: 'Mensagem notícias',
          PAGE_DESCRIPTION:
            'Preencha com os campos com as mensagens indicadas:',
          ADD: {
            LABELS: {
              TITLE: 'Título',
              DESCRIPTION: 'Descrição',
              FILE_URL: 'Faça o upload de um arquivo',
              DATE: 'Data',
              BUTTON: 'Salvar',
            },
          },
        },
      },

      PASSWORDS: {
        ROUTE: {
          NAME: 'ATIVIDADES',
          REPORT: 'PASSWORDS',
          DETAILS: 'DETALHES',
          URL_ADD: '/atividades/senhas-diarias/detalhes/:id?',
        },
        MESSAGES: {
          FILE_SUCCESS: 'Arquivo adicionado com sucesso',
          SUBMIT_ERROR: 'Arquivo faltando',
          INVALID_DATE: 'Data inválida',
        },
        LABELS: {
          TITLE: 'Título',
          DESCRIPTION: 'Descrição',
          DATE: 'Data',
          UPLOAD: 'Upload de áudio',
          SUBMIT: 'Cadastrar',
          ACTIONS: 'Ações',
        },
        TITLE: 'Senhas diárias',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Senhas diárias',
          PAGE_TITLE: 'Mensagens diárias',
          DESCRIPTION: 'Preencha com os campos com as mensagens indicadas:',
          ADVANCED_FILTER: {
            TITLE: 'Título',
            TITLE_FIELD: 'title',
            DESCRIPTION_FIELD: 'DESCRIPTION',
            DATE: 'Data',
            DATE_FIELD: 'date',
            ACTIONS: 'actions',
          },
          ADD: {
            LABELS: {
              TITLE: 'Título',
              DESCRIPTION: 'Descrição',
              FILE_URL: 'Faça o upload de um arquivo',
              DATE: 'Data',
              BUTTON: 'Salvar',
            },
          },
          TABLE: {
            FIELD_TITLE: 'title',
            TITLE: 'Título',
            FIELD_DATA: 'data',
            DATA: 'Data',
            FIELD_TYPE: 'type',
            TYPE: 'Tipo de Evento',
          },
        },
      },

      PASSWORDS_ADD: {
        ROUTE: {
          NAME: 'ATIVIDADES',
          REPORT: 'PASSWORDS_ADD',
          DETAILS: 'DETALHES',
        },
        TITLE: 'Senhas diárias',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Senhas diárias',
          PAGE_TITLE: 'Mensagens diárias',
          PAGE_DESCRIPTION:
            'Preencha com os campos com as mensagens indicadas:',
          ADD: {
            LABELS: {
              TITLE: 'Título',
              DESCRIPTION: 'Descrição',
              FILE_URL: 'Faça o upload de um arquivo',
              DATE: 'Data',
              DATE_PLACEHOLDER: '01/01/2021 12:00',
              BUTTON: 'Salvar',
            },
          },
        },
      },

      NOTIFICATIONS: {
        ROUTE: {
          NAME: 'ATIVIDADES',
          REPORT: 'NOTIFICATIONS',
          DETAILS: 'DETALHES',
          URL_ADD: '/atividades/notificacoes/detalhes/:id?',
        },
        MESSAGES: {
          SUCCESS: 'Notificação agendada com sucesso',
        },
        TITLE: 'Notificações',
        DETAILS: {
          PAGE_TITLE: 'Notificações',
          PAGE_DESCRIPTION:
            'Preencha os campos abaixo para editar um liderança',
          PLACEHOLDER_DATE: '11/11/2021',
          PLACEHOLDER_TIME: '14:00',
        },
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Notificações',
          PAGE_TITLE: 'Notificações',
          PAGE_DESCRIPTION: 'Notificações cadastradas na plataforma',
          ADVANCED_FILTER: {
            TITLE: 'Título',
            DATE: 'Data',
          },
          ADD: {
            LABELS: {
              TITLE: 'Título',
              DESCRIPTION: 'Descrição',
              FILE_URL: 'Faça o upload de um arquivo',
              DATE: 'Data de envio',
              BUTTON: 'Salvar',
              DATETIME: 'Data e hora',
              DATE_PLACEHOLDER: 'dd/mm/aaaa hh/mm',
            },
          },
          FIELDS: {
            ID: {
              KEY: 'id',
              TITLE: 'id',
            },
            TITLE: {
              KEY: 'title',
              TITLE: 'Título',
            },
            BODY: {
              KEY: 'body',
              TITLE: 'Corpo',
            },
            CREATED_AT: {
              KEY: 'createdAt',
              TITLE: 'Criado em',
            },
            ACTIONS: {
              KEY: 'actions',
              TITLE: 'Ações',
            },
          },
        },
      },

      NOTIFICATIONS_ADD: {
        ROUTE: {
          NAME: 'ATIVIDADES',
          REPORT: 'NOTIFICATIONS_ADD',
          DETAILS: 'DETALHES',
        },
        TITLE: 'Notificações',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          TITLE: 'Notificações',
          PAGE_TITLE: 'Notificações',
          PAGE_DESCRIPTION:
            'Preencha com os campos com as mensagens indicadas:',
          ADD: {
            LABELS: {
              TITLE: 'Título',
              DESCRIPTION: 'Descrição',
              FILE_URL: 'Faça Upload de um arquivo',
              DATE: 'Data',
              BUTTON: 'Salvar',
            },
          },
        },
      },

      MY_SYNOD: {
        ROUTE: {
          NAME: 'MEU_SINODO',
          INITIAL: 'INITIAL',
          LEADERS: 'LEADERS',
          OCCUPATION_AREA: 'OCCUPATION_AREA',
        },
        INITIAL: {
          PAGE_TITLE: 'Mensagem início',
          PAGE_DESCRIPTION:
            'Preencha com os campos com as mensagens indicadas:',
        },
        LEADERS: {
          PAGE_TITLE: 'Mensagem lideranças',
          PAGE_DESCRIPTION:
            'Preencha com os campos com as mensagens indicadas:',
        },
        OCCUPATION_AREA: {
          PAGE_TITLE: 'Mensagem área de ocupação',
          PAGE_DESCRIPTION:
            'Preencha com os campos com as mensagens indicadas:',
        },
        FORM: {
          TITLE: 'Insira o título da mensagem',
          DESCRIPTION: 'Insira a mensagem',
          OTHER_INFO: 'Insira as informações adicionais',
          URL: 'Insira a URL do vídeo a ser postado no aplicativo',
        },
        SUCCESS_EDIT_MESSAGE: 'Sínodo editado com sucesso!',
      },

      SYNOD: {
        ROUTE: {
          NAME: 'USUARIOS',
          REPORT: 'SYNOD',
          DETAILS: 'DETALHES',
          ADMIN_REPORT: 'ADMIN_REPORT',
        },
        TITLE: 'SÍNODOS',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          ADD: {
            LABELS: {
              NAME: 'Nome do sínodo',
              PARISH: 'Nome da paróquia',
              COMMUNITY: 'Nome da comunidade',
            },
          },
          TITLE: 'Relatório',
          PAGE_TITLE: 'Relatório de sínodos',
          PAGE_DESCRIPTION: 'Listagem de sínodos cadastrados na plataforma',
          PAGE_TITLE_DETAILS: 'Detalhes',

          SEARCH_BAR: {
            SEARCH: {
              LABEL: 'O que deseja procurar?',
            },
            STATUS: {
              LABEL: 'STATUS',
            },
            FILTERS: {
              LABEL: 'FILTROS',
            },
          },

          ADVANCED_FILTER: {
            USERNAME: 'Nome',
            INIT_TITLE: 'Nome Inicial',
            LEADER_SHIP_TITLE: 'liderança do Sínodo',
            CREATED_AT: 'Criado em',
          },
        },

        DETAILS: {
          SUCCESS_ADD_MESSAGE: 'Sínodo adicionado com sucesso!',
          UNDEFINED: 'Não definido',
          TITLE: 'Sínodo',
          PAGE_TITLE: 'Sínodo',
          PAGE_TITLE_BREAD: 'Detalhe sínodo',
          PAGE_DESCRIPTION: 'Data de cadastro: 05/10/2020',

          SEARCH_BAR: {
            SEARCH: {
              LABEL: 'O que deseja procurar?',
            },
            STATUS: {
              LABEL: 'STATUS',
            },
            FILTERS: {
              LABEL: 'FILTROS',
            },
          },
          ADVANCED_FILTER: {
            USERNAME: 'Usuário',
            CELLPHONE: 'Celular',
          },
          TABS: {
            ONE: 'EDITAR DETALHES',
            TWO: 'PAGAMENTOS',
            THREE: 'DEPÓSITOS',
            FOUR: 'TRANSFERÊNCIAS',
            FIVE: 'SOLICITAÇÕES',
            SIX: 'CARTÕES',
          },
          FORM: {
            ERROR: {
              EMPTY_FIELD: 'Preencha todos os campos!',
            },
            BUTTON: {
              LABEL: 'Salvar',
            },
            BACK: {
              LABEL: 'Voltar',
            },
            TITLE: 'Detalhes do sínodo',
            NAME: {
              LABEL: 'Nome sínodo',
            },
            VIDEO_URL: {
              LABEL: 'Insira a url do vídeo no Youtube',
            },
            INIT_TITLE: {
              LABEL: 'Título principal',
            },
            INIT_DESCRIPTION: {
              LABEL: 'Descrição',
            },
            LEADER_SHIP_TITLE: {
              LABEL: 'Nome liderança sinodal',
            },
            LEADER_SHIP_DESCRIPTION: {
              LABEL: 'Descrição liderança sinodal',
            },
            OCCUP_AREA_TITLE: {
              LABEL: 'Título área',
            },
            OCCUP_AREA_DESCRIPTION: {
              LABEL: 'Descrição área',
            },
            MORE_INFO: {
              LABEL: 'Informações (número de telefone, e-mail, etc)',
            },
          },
        },

        ONBOARDING: {
          TITLE: 'Onboarding',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        COMPANY: {
          TITLE: 'Empresa',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        COMPLIANCE: {
          TITLE: 'Compliance',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
      },

      PARISH: {
        PAGE_TITLE_BREAD: 'Detalhe',
        ROUTE: {
          NAME: 'USUARIOS',
          REPORT: 'PARISH',
          DETAILS: 'DETALHES',
          URL_ADD: '/usuarios/paroquia/detalhes/:id?',
        },
        TITLE: 'PARÓQUIAS',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          ADD: {
            LABELS: {
              PARISH: 'Nome da paróquia',
              SYNOD: 'Nome do sínodo',
              COMUNITY: 'Nome da comunidade',
            },
          },
          TITLE: 'Relatório',
          PAGE_TITLE: 'Relatório de paróquias',
          PAGE_DESCRIPTION: 'Paróquias cadastradas na plataforma',

          ADVANCED_FILTER: {
            USERNAME: 'Nome da Paróquia',
            CITY: 'Cidade',
            STREET: 'Rua',
          },
        },

        DETAILS: {
          UNDEFINED: 'Não definido',
          TITLE: 'Sínodo',
          PAGE_TITLE: 'Sínodo',
          PAGE_DESCRIPTION: 'Data de cadastro: 05/10/2020',

          SEARCH_BAR: {
            SEARCH: {
              LABEL: 'O que deseja procurar?',
            },
            STATUS: {
              LABEL: 'STATUS',
            },
            FILTERS: {
              LABEL: 'FILTROS',
            },
          },
          ADVANCED_FILTER: {
            USERNAME: 'Usuário',
            CELLPHONE: 'Celular',
          },
          TABS: {
            ONE: 'EDITAR DETALHES',
            TWO: 'PAGAMENTOS',
            THREE: 'DEPÓSITOS',
            FOUR: 'TRANSFERÊNCIAS',
            FIVE: 'SOLICITAÇÕES',
            SIX: 'CARTÕES',
          },
          FORM: {
            TITLE: 'Editar detalhes do usuário',
            NAME: {
              LABEL: 'Nome da paróquia',
            },
            SYNOD_ID: {
              LABEL: 'Sínodo pertencente',
            },
            ADDRESS: {
              LABEL: 'Localização',
            },
            STREET: {
              LABEL: 'Rua',
            },
            NUMBER: {
              LABEL: 'Número',
            },
            ZIPCODE: {
              LABEL: 'CEP',
            },
            NEIGHBORHOOD: {
              LABEL: 'Bairro',
            },
            CITY: {
              LABEL: 'Cidade',
            },
            STATE: {
              LABEL: 'Estado',
            },
            COMPLEMENT: {
              LABEL: 'Complemento',
            },
            LATITUDE: {
              LABEL: 'Latitude',
            },
            LONGITUDE: {
              LABEL: 'Longitude',
            },
            BACK: {
              LABEL: 'Voltar',
            },
            BUTTON: {
              LABEL: 'Salvar',
            },
            ERROR: 'Campos faltando',
          },
        },
      },

      LEADERS: {
        PAGE_TITLE_BREAD: 'Lideranças',
        ROUTE: {
          NAME: 'USUARIOS',
          REPORT: 'LEADERS',
          DETAILS: 'DETAILS',
          URL_ADD: '/usuarios/lideres/detalhes/:id?',
        },
        TITLE: 'Lideranças',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          ADD: {
            LABELS: {
              PARISH: 'Nome da paróquia',
              SYNOD: 'Nome do sínodo',
              COMUNITY: 'Nome do(a) liderança',
            },
          },
          TITLE: 'Relatório',
          PAGE_TITLE: 'Relatório de Lideranças',
          PAGE_DESCRIPTION: 'Vizualize e gerencie os lideranças',

          SEARCH_BAR: {
            SEARCH: {
              LABEL: 'O que deseja procurar?',
            },
            STATUS: {
              LABEL: 'STATUS',
            },
            FILTERS: {
              LABEL: 'FILTROS',
            },
          },

          ADVANCED_FILTER: {
            USERNAME: 'Nome da liderança',
            CITY: 'Nome da cidade',
            STREET: 'Nome da rua',
          },
        },

        DETAILS: {
          UNDEFINED: 'Não definido',
          TITLE: 'Lideranças',
          PAGE_TITLE: 'Lideranças',
          PAGE_DESCRIPTION: 'Adicione um liderança',

          SEARCH_BAR: {
            SEARCH: {
              LABEL: 'O que deseja procurar?',
            },
            STATUS: {
              LABEL: 'STATUS',
            },
            FILTERS: {
              LABEL: 'FILTROS',
            },
          },
          ADVANCED_FILTER: {
            USERNAME: 'Usuário',
            CELLPHONE: 'Celular',
          },
          TABS: {
            ONE: 'EDITAR DETALHES',
            TWO: 'PAGAMENTOS',
            THREE: 'DEPÓSITOS',
            FOUR: 'TRANSFERÊNCIAS',
            FIVE: 'SOLICITAÇÕES',
            SIX: 'CARTÕES',
          },
          FORM: {
            TITLE: 'Editar detalhes do(a) liderança',
            NAME: {
              LABEL: 'Nome do(a) liderança',
            },
            SYNOD: {
              ID: {
                LABEL: 'ID sínodo',
              },
              LABEL: 'Sinodo pertencente',
            },
            PARISH: {
              ID: {
                LABEL: 'ID paróquia',
              },
              LABEL: 'Paróquia pertencente',
            },

            ROLE_TYPE: {
              COUNSELOR: 'Conselheiro(a)',
              BOARD_MEMBER: 'Diretor(a)',
              MINISTER: 'Ministro(a)',
            },

            ROLE_DESCRIPTION: {
              LABEL: 'Descrição de liderança',
            },

            INSTITUTION: {
              LABEL: 'Instituição pertencente',
            },

            BUTTON: {
              LABEL: 'Salvar',
            },
            MESSAGES: {
              SUCCESS: 'liderança adicionado com sucesso',
            },
          },
        },

        ONBOARDING: {
          TITLE: 'Onboarding',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        COMPANY: {
          TITLE: 'Empresa',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        COMPLIANCE: {
          TITLE: 'Compliance',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
      },

      COMMUNITY: {
        PAGE_TITLE_BREAD: 'Detalhes',
        ROUTE: {
          NAME: 'USUARIOS',
          REPORT: 'COMMUNITY',
          DETAILS: 'DETALHES',
          URL_ADD: '/usuarios/comunidade/detalhes/:id?',
        },
        TITLE: 'COMUNIDADES',
        REPORT: {
          BUTTON_LINK: 'Adicionar',
          ADD: {
            LABELS: {
              PARISH: 'Nome da paróquia',
              SYNOD: 'Nome do sínodo',
              COMUNITY: 'Nome da comunidade',
            },
          },
          TITLE: 'Relatório',
          PAGE_TITLE: 'Relatório de Comunidades',
          PAGE_DESCRIPTION: 'Comunidades cadastradas na plataforma',

          SEARCH_BAR: {
            SEARCH: {
              LABEL: 'O que deseja procurar?',
            },
            STATUS: {
              LABEL: 'STATUS',
            },
            FILTERS: {
              LABEL: 'FILTROS',
            },
          },

          ADVANCED_FILTER: {
            USERNAME: 'Nome da Comunidade',
            CITY: 'Nome da cidade',
            STREET: 'Nome da rua',
          },
        },

        DETAILS: {
          UNDEFINED: 'Não definido',
          TITLE: 'Sínodo',
          PAGE_TITLE: 'Sínodo',
          PAGE_TITLE_BREAD: 'Detalhe sínodo',
          PAGE_DESCRIPTION: 'Data de cadastro: 05/10/2020',

          SEARCH_BAR: {
            SEARCH: {
              LABEL: 'O que deseja procurar?',
            },
            STATUS: {
              LABEL: 'STATUS',
            },
            FILTERS: {
              LABEL: 'FILTROS',
            },
          },
          ADVANCED_FILTER: {
            USERNAME: 'Usuário',
            CELLPHONE: 'Celular',
          },
          TABS: {
            ONE: 'EDITAR DETALHES',
            TWO: 'PAGAMENTOS',
            THREE: 'DEPÓSITOS',
            FOUR: 'TRANSFERÊNCIAS',
            FIVE: 'SOLICITAÇÕES',
            SIX: 'CARTÕES',
          },
          FORM: {
            TITLE: 'Editar detalhes do usuário',
            NAME: {
              LABEL: 'Nome da comunidade',
            },
            PARISH_ID: {
              LABEL: 'Sínodo pertencente',
            },
            ADDRESS: {
              LABEL: 'Localização',
            },
            STREET: {
              LABEL: 'Rua',
            },
            NUMBER: {
              LABEL: 'Número',
            },
            ZIPCODE: {
              LABEL: 'CEP',
            },
            NEIGHBORHOOD: {
              LABEL: 'Bairro',
            },
            CITY: {
              LABEL: 'Cidade',
            },
            STATE: {
              LABEL: 'Estado',
            },
            COMPLEMENT: {
              LABEL: 'Complemento',
            },
            LATITUDE: {
              LABEL: 'Latitude',
            },
            LONGITUDE: {
              LABEL: 'Longitude',
            },
            BACK: {
              LABEL: 'Voltar',
            },
            BUTTON: {
              LABEL: 'Salvar',
            },
          },
        },

        ONBOARDING: {
          TITLE: 'Onboarding',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        COMPANY: {
          TITLE: 'Empresa',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        COMPLIANCE: {
          TITLE: 'Compliance',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
      },

      PUSH_NOTIFICATIONS: {
        TITLE: 'PUSH NOTIFICATIONS',
        REPORT: {
          TITLE: 'Push notifications',
          PAGE_TITLE: 'Push notifications',
          PAGE_DESCRIPTION: 'Notificações cadastradas na plataforma',

          SEARCH_BAR: {
            SEARCH: {
              LABEL: 'O que deseja procurar?',
            },
            STATUS: {
              LABEL: 'STATUS',
            },
            FILTERS: {
              LABEL: 'FILTROS',
            },
          },

          ADVANCED_FILTER: {
            USERNAME: 'Usuário',
            CELLPHONE: 'Celular',
          },
        },

        DETAILS: {
          TITLE_ADD: 'Adicionar',
          TITLE_EDIT: 'Editar push notification',
          DESCRIPTION_ADD:
            'Preencha o formulário abaixo para criar uma notificação',
          STEPS: {
            ONE: 'Filtre os usuários',
            TWO: 'Defina os dados da notificação',
            THREE: 'Ajuste a recorrência e agendamento',
          },
          FORM: {
            TITLE: {
              LABEL: 'Título',
            },
            DESCRIPTION: {
              LABEL: 'Descrição',
            },
            SCHEDULE: {
              LABEL: 'Agendamento',
              NOW: 'Enviar agora',
              SCHEDULED: 'Selecionar data',
            },
            SEND_TYPE: {
              LABEL: 'Forma de envio',
              ALL: 'Todos os usuários',
              CSV: 'Selecionar usuários',
              CSV_BUTTON_TEXT: 'Selecione o arquivo CSV',
              CSV_BUTTON_TEXT_CHANGE: 'Selecionar outro arquivo',
              CSV_TEMPLATE_TEXT: 'Download do template',
            },
            RECURRENCE: {
              LABEL: 'Recorrência',
              JUST_SEND: 'Apenas uma vez',
              DAILY: 'Diariamente',
              DAILY_TIME: 'Hora do dia',
              WEEKLY: 'Semanalmente',
              MONTLY: 'Mensalmente',
              MONTLY_DAY: 'Dia do mês',
              EXPIRATION_DATE: 'Data de expiração',
              UNDEFINED_EXPIRATION: 'Deixe em branco para nunca expirar.',
              DAYS_OF_WEEK: {
                TITLE: 'Dia da semana',
                ONE: 'Domingo',
                TWO: 'Segunda-feira',
                THREE: 'Terça-feira',
                FOUR: 'Quarta-feira',
                FIVE: 'Quinta-feira',
                SIX: 'Sexta-feira',
                SEVEN: 'Sábado',
              },
            },
            USERS_AMOUNT: {
              LABEL: 'Será enviado para',
              LABEL_TWO: 'usuário(s).',
            },
          },
        },

        ONBOARDING: {
          TITLE: 'Onboarding',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        COMPANY: {
          TITLE: 'Empresa',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        COMPLIANCE: {
          TITLE: 'Compliance',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
      },

      REPORTS: {
        TITLE: 'RELATÓRIOS',
        REPORT: {
          TITLE: 'Movimentação',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        ONBOARDING: {
          TITLE: 'Tarifas',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        COMPANY: {
          TITLE: 'Resumo transações',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        COMPLIANCE: {
          TITLE: 'Consolidação diária',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
      },

      MOVIMENTATION: {
        TITLE: 'MOVIMENTAÇÃO',
        SIDEBAR_ICON_URL: '',

        PAYMENTS: {
          TITLE: 'Pagamentos',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        RECEIPTS: {
          TITLE: 'Recebimentos',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
      },

      SETTINGS: {
        TITLE: 'CONFIGURAÇÕES',
        SECURITY: {
          TITLE: 'Segurança',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        ACCOUNTS: {
          TITLE: 'Contas',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
        GENERAL: {
          TITLE: 'Geral',
          PAGE_DESCRIPTION: 'Lorem Ipsum Dollor Sit Amet',
        },
      },
      NOT_FOUND: {
        TITLE: 'Erro 404',
        PAGE_DESCRIPTION:
          'Parece que não encontramos a página solicitada.\nVerifique a url digitada e tente novamente.',
      },
    },
  },

  ENUMERATORS: {
    PROFILE_TYPE: {
      ADMIN: {
        VALUE: '1',
        LABEL: 'Nacional',
      },
      SYNOD_ADMIN: {
        VALUE: '2',
        LABEL: 'Sinodal',
      },
      APP_USER: {
        VALUE: '3',
        LABEL: 'Usuário(a)',
      },
    },

    EVENT_TYPE: {
      EVENT: 'Evento',
      REMIDER: 'Lembrete',
      OFFERING: 'Oferta',
    },

    SCOPE_TYPE: {
      SYNODAL: 'Sínodal',
      NATIONAL: 'Nacional',
      PAROCHIAL: 'Paroquial',
    },

    LEADER_ROLE: {
      MINISTER: {
        VALUE: '1',
        LABEL: 'Ministas e ministros',
      },
      COUNCIL: {
        VALUE: '2',
        LABEL: 'Diretoria sinodal',
      },
      BOARD: {
        VALUE: '3',
        LABEL: 'Conselhos, coordenações e setores sinodais',
      },
    },
  },

  SHARED: {
    FORMAT_DATE: 'dd/MM/yyyy',
    FORMAT_HOUR: 'HH:mm',
    CURRENCY_SYMBOL: 'R$',
    SELECT_SOMETHING: 'Selecione...',
    ADD_NEW: 'Adicionar',
    IMPORT_NEW: 'Importar CSV',
    BACK: 'Voltar',
    SAVE: 'Salvar',
    ADD_ID: 'adicionar',
    CANCEL: 'Cancelar',
    DAY: 'Dia',
  },
};

export default ptBr;
