import getInstance from './instance';

const BASE_URL: string = '/user';

const AdminRepository = {
  getReport: async (params: advancedFilterModels.IAdminAdvancedFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get(`${BASE_URL}`, { params });

    return data;
  },

  getDetail: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.get(`${BASE_URL}/${id}`);

    return data;
  },

  create: async (params: models.Admin) => {
    const instance = await getInstance();
    const { data } = await instance.post(`${BASE_URL}/admin`, params);

    return data;
  },

  remove: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.delete(`${BASE_URL}/admin/${id}`);

    return data;
  },

  update: async (id?: string, params?: any) => {
    const instance = await getInstance();
    const { data } = await instance.put(`${BASE_URL}/${id}`, params);

    return data;
  },
};

export default AdminRepository;
