import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getStackPath } from '@portal/config/routes';

import CommunityNavigationStack from './Community/CommunityNavigationStack';
import LeadersNavigationStack from './Leaders/LeadersNavigationStack';
import ParishNavigationStack from './Parish/ParishNavigationStack';
import SynodNavigationStack from './Synod/SynodNavigationStack';
import AdminNavigationStack from './Admin/AdminNavigationStack';
import UserNavigationStackInner from './User/UserNavigationStack';

const UserNavigationStack = () => {
  return (
    <Switch>
      <Route path={getStackPath('USER')}>
        <AdminNavigationStack />
        <UserNavigationStackInner />
        <SynodNavigationStack />
        <ParishNavigationStack />
        <LeadersNavigationStack />
        <CommunityNavigationStack />
      </Route>
    </Switch>
  );
};

export default UserNavigationStack;
