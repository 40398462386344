import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '~/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import AdminDetails from './Details/AdminDetails';
import AdminReport from './Report/AdminReport';
import { getPagetType } from '~/utils/page';
import { PAGE_TYPE } from '~/enum/pageType';

const AdminNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('USER', 'ADMIN_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.USER.ADMIN.REPORT.TITLE')}
          pageDescription={translate('PAGES.PANEL.ADMIN.REPORT.PAGE_DESCRIPTION')}
        >
          <AdminReport />
        </PanelContent>
      </Route>
      <Route path={`${getRouteStackPath('USER', 'ADMIN_DETAILS')}/:id`}>
        <PanelContent
          pageTitle={translate(`PAGES.PANEL.USER.ADMIN.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
          pageDescription={translate(`PAGES.PANEL.USER.ADMIN.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
        >
          <AdminDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default AdminNavigationStack;
