import { PARISH_ALL, PARISH_DETAIL, PARISH_REPORT } from '~/actions/actionTypes';

const initialState: reducers.ParishReducer = {
  detail: null,
  list: null,
  all: [],
};

const ParishReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PARISH_DETAIL:
      state = {
        ...state,
        detail: action.payload,
      };
      break;

    case PARISH_REPORT:
      state = {
        ...state,
        list: action.payload,
      };
      break;

    case PARISH_ALL:
      state = {
        ...state,
        all: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default ParishReducer;
