import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import CommunityDetails from './Details/CommunityDetails';
import CommunityReport from './Report/CommunityReport';
import { getPagetType } from '~/utils/page';
import { PAGE_TYPE } from '~/enum/pageType';

const communityNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('USER', 'COMMUNITY_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.USER.COMMUNITY.REPORT.TITLE')}
          pageDescription={translate('PAGES.PANEL.USER.COMMUNITY.REPORT.DESCRIPTION')}
        >
          <CommunityReport />
        </PanelContent>
      </Route>

      <Route path={`${getRouteStackPath('USER', 'COMMUNITY_DETAILS')}/:id`}>
        <PanelContent
          pageTitle={translate(`PAGES.PANEL.USER.COMMUNITY.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
          pageDescription={translate(`PAGES.PANEL.USER.COMMUNITY.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
        >
          <CommunityDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default communityNavigationStack;
