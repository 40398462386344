import getInstance from './instance';
import { ICommunityAdvancedFilter } from '~/models/advanced-filters';

const BASE_URL: string = '/community';

const CommunityApi = {

  getReport: async (params: advancedFilterModels.ICommunityAdvancedFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get(BASE_URL, { params });

    return data;
  },

  getDetail: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.get(`${BASE_URL}/${id}`);

    return data;
  },

  create: async (params: models.Community) => {
    const instance = await getInstance();
    const { data } = await instance.post(BASE_URL, params);

    return data;
  },

  remove: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.delete(`${BASE_URL}/${id}`);

    return data;
  },

  update: async (id?: string, params?: any) => {
    const instance = await getInstance();
    const { data } = await instance.put(`${BASE_URL}/${id}`, params);

    return data;
  },

  getAllCommunitys: async (params: ICommunityAdvancedFilter) => {
    const instance = getInstance();
    const { data } = await instance.get(`${BASE_URL}/all`, { params });

    return data;
  }
};

export default CommunityApi;
