import * as MessageService from '~/services/message';
import centralNavigationService from '~/services/navigation';

import { ANNOUNCEMENT_DETAIL, ANNOUNCEMENT_REPORT } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';
import AnnouncementRequests from '~/repositories/announcement';

export const cleanDetails = () => async (dispatch: any) => {
  dispatch({
    payload: null,
    type: ANNOUNCEMENT_DETAIL,
  });
};

export const getReport = (
  searchParams: advancedFilterModels.IAnnouncementAdvancedFilter
) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.AnnouncementApi> = await AnnouncementRequests.getReport(
      {
        ...searchParams,
        offset: searchParams.page - 1,
        limit: searchParams.pageSize,
      }
    );

    dispatch({
      payload,
      type: ANNOUNCEMENT_REPORT,
    });
  } catch (err) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getDetail = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.Community = await AnnouncementRequests.getDetail(id);

    dispatch({
      payload,
      type: ANNOUNCEMENT_DETAIL,
    });
  } catch (err) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const create = (params: models.AnnouncementApi) => async (
  dispatch: any
) => {
  dispatch(increaseLoading());
  try {
    await AnnouncementRequests.create(params);
    MessageService.success(
      'PAGES.PANEL.USER.COMMUNITY.MESSAGES.SUCCESS_ADD_MESSAGE'
    );
    await centralNavigationService.back();
  } catch (err) {
    MessageService.error(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const remove = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await AnnouncementRequests.remove(id);
    MessageService.success(
      'PAGES.PANEL.USER.COMMUNITY.MESSAGES.SUCCESS_REMOVE_MESSAGE'
    );
  } catch (err) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const update = (id?: string, params?: any) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await AnnouncementRequests.update(id, params);
    MessageService.success(
      'PAGES.PANEL.USER.COMMUNITY.MESSAGES.SUCCESS_EDIT_MESSAGE'
    );
    await centralNavigationService.back();
  } catch (err) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};
