import { LEADER_DETAIL, LEADER_REPORT } from '~/actions/actionTypes';

const initialState: reducers.LeaderReducer = {
  detail: null,
  list: null,
};

const LeaderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LEADER_DETAIL:
      state = {
        ...state,
        detail: action.payload,
      };
      break;

    case LEADER_REPORT:
      state = {
        ...state,
        list: action.payload,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default LeaderReducer;
