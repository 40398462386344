import { translate } from '~/services/i18n';

export enum LeaderRole {
  MINISTER = 1,
  COUNCIL = 2,
  BOARD = 3,
}

export const leaderRoleArray = [
  {
    value: LeaderRole.MINISTER,
    i18n: 'ENUMERATORS.LEADER_ROLE.MINISTER.LABEL',
  },
  {
    value: LeaderRole.COUNCIL,
    i18n: 'ENUMERATORS.LEADER_ROLE.COUNCIL.LABEL',
  },
  { value: LeaderRole.BOARD, i18n: 'ENUMERATORS.LEADER_ROLE.BOARD.LABEL' },
];

export const getLeaderRoleTitle = (value: number) => {
  let response: string = '--';

  if (value) {
    const found = leaderRoleArray.find((o) => o.value === value);

    if (found) {
      response = translate(found.i18n);
    }
  }

  return response;
};
