import { PlusCircleOutlined } from '@ant-design/icons';
import { CellParams } from '@material-ui/data-grid';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useReduxState } from '~/hooks/useReduxState';

import * as AdminActions from '~/actions/admin';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import DataTable from '~/components/DataTable/DataTable';
import DataTableActions from '~/components/DataTableActions/DataTableActions';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import PanelContentSearchBar from '~/components/PanelContentSearchBar/PanelContentSearchBar';
import { REPORT_PAGE_SIZE } from '~/config/env';
import { translate } from '~/services/i18n';
import NavigationService from '~/services/navigation';
import { getRouteStackPath } from '~/config/routes';
import { getProfileTypeTitle } from '~/enum/profileType';

const initialValues: advancedFilterModels.IAdminAdvancedFilter = {
  name: '',
  page: 1,
  sort: 'desc',
  orderBy: 'createdAt',
  pageSize: REPORT_PAGE_SIZE,
  profileType: [1, 2],
};

const AdminReport: React.FC = () => {
  const dispatch = useDispatch();
  const [advancedFilters, setAdvancedFilters] = useState(initialValues);
  const { admin } = useReduxState();

  useEffect(() => {
    const filter = NavigationService.getQuery();
    onSearch({
      ...advancedFilters,
      ...filter,
    });
  }, []);

  const onSearch = (filters: advancedFilterModels.IAdminAdvancedFilter) => {
    dispatch(AdminActions.getReport(filters));
  };

  const onRemove = (id: string) => {
    dispatch(AdminActions.remove(id));
  };

  return (
    <div className="report">
      <PanelContentSearchBar
        onFormSubmit={(form: any) => onSearch({
          ...advancedFilters,
          name: form.search,
        })}
      />
      <div className="report__header">
        <Row>
          <Col lg={6}>
            <PanelContentHeader
              pageTitle={translate('PAGES.PANEL.USER.ADMIN.REPORT.TITLE')}
              pageDescription={translate('PAGES.PANEL.USER.ADMIN.REPORT.DESCRIPTION')}
            />
          </Col>
          <Col
            lg={6}
            className="text-right"
          >
            <Link to={`${getRouteStackPath('USER', 'ADMIN_DETAILS')}/${translate('SHARED.ADD_ID')}`}>
              <AdvancedButton
                text={translate('SHARED.ADD_NEW')}
                endIcon={<PlusCircleOutlined />}
              />
            </Link>
          </Col>
        </Row>
      </div>

      <div className="report__table">
        <Row>
          <Col>
            <div className="report__table__inner">
              <DataTable
                rows={admin?.list?.rows || []}
                rowCount={admin?.list?.count || 0}
                columns={[
                  {
                    field: translate('PAGES.PANEL.USER.ADMIN.REPORT.FIELDS.ID.KEY'),
                    headerName: translate('PAGES.PANEL.USER.ADMIN.REPORT.FIELDS.ID.TITLE'),
                    sortable: false,
                    hide: true,
                    flex: 1,
                  },
                  {
                    field: translate('PAGES.PANEL.USER.ADMIN.REPORT.FIELDS.NAME.KEY'),
                    headerName: translate('PAGES.PANEL.USER.ADMIN.REPORT.FIELDS.NAME.TITLE'),
                    flex: 1,
                  },
                  {
                    field: translate('PAGES.PANEL.USER.ADMIN.REPORT.FIELDS.EMAIL.KEY'),
                    headerName: translate('PAGES.PANEL.USER.ADMIN.REPORT.FIELDS.EMAIL.TITLE'),
                    flex: 1,
                  },
                  {
                    field: translate('PAGES.PANEL.USER.ADMIN.REPORT.FIELDS.PROFILE_TYPE.KEY'),
                    headerName: translate('PAGES.PANEL.USER.ADMIN.REPORT.FIELDS.PROFILE_TYPE.TITLE'),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return (
                        <>
                          {getProfileTypeTitle(o.value as number)}
                        </>
                      );
                    },
                  },
                  {
                    field: translate('PAGES.PANEL.USER.ADMIN.REPORT.FIELDS.CREATED_AT.KEY'),
                    headerName: translate('PAGES.PANEL.USER.ADMIN.REPORT.FIELDS.CREATED_AT.TITLE'),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return (
                        <>
                          {DateTime.fromISO(o.value as string).toLocaleString(
                            DateTime.DATETIME_SHORT
                          )}
                        </>
                      );
                    },
                  },
                  {
                    field: translate('PAGES.PANEL.USER.ADMIN.REPORT.FIELDS.ACTIONS.KEY'),
                    headerName: translate('PAGES.PANEL.USER.ADMIN.REPORT.FIELDS.ACTIONS.TITLE'),
                    align: 'center',
                    headerAlign: 'center',
                    renderCell: (o: CellParams) => (
                      <DataTableActions
                        row={o.row}
                        basePath={getRouteStackPath('USER', 'ADMIN_DETAILS')}
                        onRemove={onRemove}
                      />
                    ),
                  },
                ]}
                pageSize={advancedFilters.pageSize}
                page={advancedFilters.page}
                sort={advancedFilters.sort}
                orderBy={advancedFilters.orderBy}
                onChange={(filters) => {
                  const searchFilters = {
                    ...advancedFilters,
                    ...filters,
                  };
                  setAdvancedFilters(searchFilters);
                  onSearch(searchFilters);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AdminReport;
