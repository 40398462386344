import ScheduleRequests from '~/repositories/schedule';
import * as MessageService from '~/services/message';
import centralNavigationService from '~/services/navigation';

import { SCHEDULE_DETAIL, SCHEDULE_REPORT } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';

export const cleanDetails = () => async (dispatch: any) => {
  dispatch({
    payload: null,
    type: SCHEDULE_DETAIL,
  });
};

export const getReport = (
  searchParams: advancedFilterModels.IScheduleAdvancedFilter
) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.Schedule> = await ScheduleRequests.getReport(
      {
        ...searchParams,
        offset: searchParams.page - 1,
        limit: searchParams.pageSize,
        title: searchParams.name,
      }
    );

    dispatch({
      payload,
      type: SCHEDULE_REPORT,
    });
  } catch (err) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getDetail = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.Schedule = await ScheduleRequests.getDetail(id);

    dispatch({
      payload,
      type: SCHEDULE_DETAIL,
    });
  } catch (err) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const create = (params: models.AddSchedule) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await ScheduleRequests.create(params);
    MessageService.success(
      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.MESSAGES.SUCCESS_ADD_MESSAGE'
    );
    await centralNavigationService.back();
  } catch (err) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const remove = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await ScheduleRequests.remove(id);
    MessageService.success(
      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.MESSAGES.SUCCESS_REMOVE_MESSAGE'
    );
  } catch (err) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const update = (id?: string, params?: any) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await ScheduleRequests.update(id, params);
    MessageService.success(
      'PAGES.PANEL.NEW_ACTIVITIES.SCHEDULE.MESSAGES.SUCCESS_EDIT_MESSAGE'
    );
    await centralNavigationService.back();
  } catch (err) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};
