import * as MessageService from '~/services/message';

import { decreaseLoading, increaseLoading } from './loading';
import FileRequests from '~/repositories/file';

export const create = (
  params: models.CreateFile,
  callback: (file: models.FileItem) => void
) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const url = await FileRequests.create(params);
    callback(url);
  } catch (err) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};
