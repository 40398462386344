import { LeftOutlined, PlusCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import PanelContentBreadcrumb from '~/components/PanelContentBreadcrumb/PanelContentBreadcrumb';

import { maskPhone, unmaskField } from '~/services/masks';
import { translate } from '~/services/i18n';
import * as MessageService from '~/services/message';

import * as UserActions from '~/actions/user';

import { getRouteStackPath } from '~/config/routes';

import { getPagetType } from '~/utils/page';

import { PAGE_TYPE } from '~/enum/pageType';

import { useReduxState } from '~/hooks/useReduxState';
import { ProfileType } from '~/enum/profileType';
import AdvancedCheckbox from '~/components/AdvancedCheckbox/AdvancedCheckbox';

const formInitialValues: models.User = {
  id: '',
  name: '',
  email: '',
  cellphone: '',
  profileType: ProfileType.APP_USER,

  mustChangePassword: false,
  password: '',
  confirmPassword: '',
};

const UserDetails: React.FC = () => {
  const [form, setForm] = useState(formInitialValues);
  const [pageType] = useState(getPagetType());

  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const { pathname } = useLocation();

  const { user } = useReduxState();

  const onFormChange = (key: string, val: any) => {
    setForm((prevState: models.User) => ({ ...prevState, [key]: val }));
  };

  useEffect(() => {
    if (user && user.detail) {
      setForm(user.detail);
    } else {
      setForm(formInitialValues);
    }
  }, [user.detail]);

  useEffect(() => {
    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(UserActions.getDetail(params.id));
    } else {
      dispatch(UserActions.cleanDetails());
    }
  }, [pathname, pageType]);

  const onFormSubmit = () => {
    if (!form.name) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_NAME');
    }

    if (!form.cellphone) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_CELLPHONE');
    }

    if (!form.email) {
      return MessageService.error('APPLICATION.ERRORS.EMPTY_EMAIL');
    }

    if (pageType === PAGE_TYPE.ADD || form.mustChangePassword) {
      if (!form.password) {
        return MessageService.error('APPLICATION.ERRORS.EMPTY_PASSWORD');
      }

      if (form.password !== form.confirmPassword) {
        return MessageService.error('APPLICATION.ERRORS.MISMATCH_PASSWORD');
      }
    }

    const payload: any = {
      ...form,
      email: form.email.toLowerCase(),
      cellphone: `55 ${unmaskField(form.cellphone)}`,
    };

    if (pageType === PAGE_TYPE.EDIT && !form.mustChangePassword) {
      delete payload.password;
      delete payload.confirmPassword;
    }

    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(UserActions.update(user?.detail?.id, payload));
    } else {
      dispatch(UserActions.create(payload));
    }
  };

  return (
    <Container fluid className="details">
      <Row>
        <Col>
          <PanelContentBreadcrumb
            items={[
              {
                title: translate('PAGES.PANEL.USER.USER.REPORT.TITLE'),
                url: getRouteStackPath('USER', 'USER_REPORT'),
                active: true,
              },
              {
                title: translate(
                  `PAGES.PANEL.USER.USER.DETAILS.TITLE_${
                    getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                  }`
                ),
                active: false,
              },
            ]}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="details__title">
            <h3 className="details__title__text">
              {translate(
                `PAGES.PANEL.USER.USER.DETAILS.TITLE_${
                  getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                }`
              )}
            </h3>
            <p className="details__title__description">
              {translate(
                `PAGES.PANEL.USER.USER.DETAILS.PAGE_DESCRIPTION_${
                  getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'
                }`
              )}
            </p>
          </div>
          <AdvancedForm onSubmit={onFormSubmit}>
            <div className="details__form">
              <Row>
                <Col md={4}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.USER.DETAILS.FORM.FIELDS.NAME.LABEL'
                    )}
                    value={form.name}
                    onChange={(val: string) => onFormChange('name', val)}
                  />
                </Col>
                <Col md={4}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.USER.DETAILS.FORM.FIELDS.EMAIL.LABEL'
                    )}
                    value={form.email}
                    onChange={(val: string) => onFormChange('email', val)}
                    disabled={pageType === PAGE_TYPE.EDIT}
                  />
                </Col>
                <Col md={4}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.USER.USER.DETAILS.FORM.FIELDS.CELLPHONE.LABEL'
                    )}
                    value={maskPhone(form.cellphone)}
                    onChange={(val: string) => onFormChange('cellphone', val)}
                  />
                </Col>
              </Row>

              {pageType === PAGE_TYPE.EDIT && (
                <Row>
                  <Col>
                    <AdvancedCheckbox
                      label={translate(
                        'PAGES.PANEL.USER.USER.DETAILS.FORM.FIELDS.MUST_CHANGE_PASSWORD.LABEL'
                      )}
                      value={form.mustChangePassword}
                      onChange={(val: boolean) =>
                        onFormChange('mustChangePassword', val)
                      }
                    />
                  </Col>
                </Row>
              )}

              {(pageType === PAGE_TYPE.ADD || form.mustChangePassword) && (
                <Row>
                  <Col md={4}>
                    <AdvancedInput
                      type="password"
                      label={translate(
                        'PAGES.PANEL.USER.USER.DETAILS.FORM.FIELDS.PASSWORD.LABEL'
                      )}
                      value={form.password}
                      onChange={(val: string) => onFormChange('password', val)}
                    />
                  </Col>
                  <Col md={4}>
                    <AdvancedInput
                      type="password"
                      label={translate(
                        'PAGES.PANEL.USER.USER.DETAILS.FORM.FIELDS.CONFIRM_PASSWORD.LABEL'
                      )}
                      value={form.confirmPassword}
                      onChange={(val: string) =>
                        onFormChange('confirmPassword', val)
                      }
                    />
                  </Col>
                </Row>
              )}

              <div className="details__form__actions">
                <Row>
                  <Col md={6}>
                    <Link to={getRouteStackPath('USER', 'USER_REPORT')}>
                      <AdvancedButton
                        variant="text"
                        text={translate('SHARED.BACK')}
                        startIcon={<LeftOutlined />}
                      />
                    </Link>
                  </Col>
                  <Col md={6} className="text-right">
                    <AdvancedButton
                      type="submit"
                      text={translate('SHARED.SAVE')}
                      endIcon={<PlusCircleOutlined />}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </AdvancedForm>
        </Col>
      </Row>
    </Container>
  );
};

export default UserDetails;
