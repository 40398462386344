import React from 'react';

const AdvancedAutocomplete = ({
  value,
  placeholder,
  onChange,
}: any) => {
  return (
    <div className="advanced-input">
      <AdvancedAutocomplete
        value={value || ''}
        placeholder={placeholder}
        onChange={() => onChange(value)}
        fullWidth
      />
    </div>
  );
};

export default AdvancedAutocomplete;
