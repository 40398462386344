export const INCREASE_LOADING = 'INCREASE_LOADING';
export const DESCREASE_LOADING = 'DESCREASE_LOADING';

// auth types
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_ME = 'AUTH_ME';
export const AUTH_CHECK_LOGGED = 'AUTH_CHECK_LOGGED';

// user types
export const USER_REPORT = 'USER_REPORT';
export const USER_DETAIL = 'USER_DETAIL';
export const USER_LIST = 'USER_LIST';
export const USER_ME = 'USER_ME';

export const ADMIN_REPORT = 'ADMIN_REPORT';
export const ADMIN_DETAIL = 'ADMIN_DETAIL';

export const SYNOD_REPORT = 'SYNOD_REPORT';
export const SYNOD_ALL = 'SYNOD_ALL';
export const SYNOD_DETAIL = 'SYNOD_DETAIL';

export const PARISH_REPORT = 'PARISH_REPORT';
export const PARISH_DETAIL = 'PARISH_DETAIL';
export const PARISH_ALL = 'PARISH_ALL';

export const LEADER_REPORT = 'LEADER_REPORT';
export const LEADER_DETAIL = 'LEADER_DETAIL';

export const COMMUNITY_REPORT = 'COMMUNITY_REPORT';
export const COMMUNITY_DETAIL = 'COMMUNITY_DETAIL';
export const COMMUNITY_ALL = 'COMMUNITY_ALL';

export const NOTIFICATION_REPORT = 'NOTIFICATION_REPORT';

export const ANNOUNCEMENT_REPORT = 'ANNOUNCEMENT_REPORT';
export const ANNOUNCEMENT_DETAIL = 'ANNOUNCEMENT_DETAIL';

export const SCHEDULE_REPORT = 'SCHEDULE_REPORT';
export const SCHEDULE_DETAIL = 'SCHEDULE_DETAIL';

export const NEWS_REPORT = 'NEWS_REPORT';
export const NEWS_DETAIL = 'NEWS_DETAIL';

// utils types
export const GET_CEP = 'GET_CEP';
export const INSTITUTIONAL_REPORT = 'INSTITUTIONAL_REPORT';
export const INSTITUTIONAL_UPDATE = 'INSTITUTIONAL_UPDATE';
