import { PlusCircleOutlined } from '@ant-design/icons';
import { CellParams } from '@material-ui/data-grid';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useReduxState } from '~/hooks/useReduxState';

import * as LeadersActions from '~/actions/leaders';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import DataTable from '~/components/DataTable/DataTable';
import DataTableActions from '~/components/DataTableActions/DataTableActions';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import PanelContentSearchBar from '~/components/PanelContentSearchBar/PanelContentSearchBar';
import { REPORT_PAGE_SIZE } from '~/config/env';
import { translate } from '~/services/i18n';
import NavigationService from '~/services/navigation';
import { getRouteStackPath } from '~/config/routes';
import { getLeaderRoleTitle } from '~/enum/leaderRoleType';

const initialValues: advancedFilterModels.ILeadersAdvancedFilter = {
  name: '',
  page: 1,
  sort: 'desc',
  orderBy: 'createdAt',
  pageSize: REPORT_PAGE_SIZE,
};

const LeadersReport: React.FC = () => {
  const dispatch = useDispatch();
  const [advancedFilters, setAdvancedFilters] = useState(initialValues);
  const { leader } = useReduxState();

  useEffect(() => {
    const filter = NavigationService.getQuery();
    onSearch({
      ...advancedFilters,
      ...filter,
    });
  }, []);

  const onSearch = (filters: advancedFilterModels.ILeadersAdvancedFilter) => {
    dispatch(LeadersActions.getReport(filters));
  };

  const onRemove = async (id: string) => {
    await dispatch(LeadersActions.remove(id));

    const filter = NavigationService.getQuery();
    onSearch({
      ...advancedFilters,
      ...filter,
    });
  };

  return (
    <div className="report">
      <PanelContentSearchBar
        onFormSubmit={(form: any) =>
          onSearch({
            ...advancedFilters,
            name: form.search,
          })
        }
      />
      <div className="report__header">
        <Row>
          <Col lg={6}>
            <PanelContentHeader
              pageTitle={translate('PAGES.PANEL.USER.LEADERS.REPORT.TITLE')}
              pageDescription={translate(
                'PAGES.PANEL.USER.LEADERS.REPORT.DESCRIPTION'
              )}
            />
          </Col>
          <Col lg={6} className="text-right">
            <Link
              to={`${getRouteStackPath('USER', 'LEADER_DETAILS')}/${translate(
                'SHARED.ADD_ID'
              )}`}
            >
              <AdvancedButton
                text={translate('SHARED.ADD_NEW')}
                endIcon={<PlusCircleOutlined />}
              />
            </Link>
          </Col>
        </Row>
      </div>

      <div className="report__table">
        <Row>
          <Col>
            <div className="report__table__inner">
              <DataTable
                rows={leader?.list?.rows || []}
                rowCount={leader?.list?.count || 0}
                columns={[
                  {
                    field: translate(
                      'PAGES.PANEL.USER.LEADERS.REPORT.FIELDS.ID.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.USER.LEADERS.REPORT.FIELDS.ID.TITLE'
                    ),
                    sortable: false,
                    hide: true,
                    flex: 1,
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.USER.LEADERS.REPORT.FIELDS.NAME.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.USER.LEADERS.REPORT.FIELDS.NAME.TITLE'
                    ),
                    flex: 1,
                  },
                  {
                    field: 'roleDescription',
                    headerName: 'perfil',
                    sortable: false,
                    hide: false,
                    flex: 1,
                  },
                  {
                    field: 'roleType',
                    headerName: 'função',
                    sortable: false,
                    hide: false,
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return (
                        <>
                          {typeof o.value === 'number' &&
                            getLeaderRoleTitle(o.value)}
                        </>
                      );
                    },
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.USER.LEADERS.REPORT.FIELDS.CREATED_AT.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.USER.LEADERS.REPORT.FIELDS.CREATED_AT.TITLE'
                    ),
                    flex: 1,
                    renderCell: (o: CellParams) => {
                      return (
                        <>
                          {DateTime.fromISO(o.value as string).toLocaleString(
                            DateTime.DATETIME_SHORT
                          )}
                        </>
                      );
                    },
                  },
                  {
                    field: translate(
                      'PAGES.PANEL.USER.LEADERS.REPORT.FIELDS.ACTIONS.KEY'
                    ),
                    headerName: translate(
                      'PAGES.PANEL.USER.LEADERS.REPORT.FIELDS.ACTIONS.TITLE'
                    ),
                    align: 'center',
                    headerAlign: 'center',
                    renderCell: (o: CellParams) => (
                      <DataTableActions
                        row={o.row}
                        basePath={getRouteStackPath('USER', 'LEADER_DETAILS')}
                        onRemove={onRemove}
                      />
                    ),
                  },
                ]}
                pageSize={advancedFilters.pageSize}
                page={advancedFilters.page}
                sort={advancedFilters.sort}
                orderBy={advancedFilters.orderBy}
                onChange={(filters) => {
                  const searchFilters = {
                    ...advancedFilters,
                    ...filters,
                  };
                  setAdvancedFilters(searchFilters);
                  onSearch(searchFilters);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LeadersReport;
