import React, { useEffect, useState } from 'react';
import {
  LeftOutlined,
  PlusCircleOutlined,
  CloudUploadOutlined,
} from '@ant-design/icons';
import * as NewsActions from '~/actions/news';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { getRouteStackPath } from '~/config/routes';
import { FileType } from '@portal/enum/newsType';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import { translate } from '~/services/i18n';
import AdvancedUploader from '@portal/components/AdvancedUploader/AdvancedUploader';
import { getPagetType } from '@portal/utils/page';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { useReduxState } from '@portal/hooks/useReduxState';
import { PAGE_TYPE } from '@portal/enum/pageType';
import * as NewsRepository from '@portal/actions/news';
import { FileItem } from '@portal/models/module';

const initialValues: models.AddNews = {
  title: '',
  description: '',
  fileUrl: '',
  date: '',
  type: FileType.IMAGE,
  files: [],
};

const NewsDetails: React.FC = (props) => {
  const [form, setForm] = useState(initialValues);
  const [fileList, setFileList] = useState<models.FileItem[]>([]);
  const [pageType] = useState(getPagetType());

  const dispatch = useDispatch();
  const params = useParams() as { id: string };
  const { pathname } = useLocation();

  const { news } = useReduxState();

  const onFormChange = (key: string, value: string) => {
    setForm((prevState: models.AddNews) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const onFormSubmit = () => {
    const files: FileItem[] = [];
    fileList.map((item: models.FileItem) => {
      files.push({
        name: item.name,
        extension: item.extension,
        type: FileType.IMAGE,
        base64: item.base64,
      });
    });

    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(NewsRepository.update(params.id, { ...form, files }));
    } else {
      dispatch(NewsRepository.create({ ...form, files }));
    }
  };

  const handleSetFileList = (file: models.FileItem) => {
    if (file.uid) {
      setFileList([...fileList, file]);
    }
  };

  const setInitialData = () => {
    const data = {
      title: news.detail?.ad?.title,
      description: news.detail?.ad?.description,
      fileUrl: news.detail?.ad?.fileUrl,
      date: news.detail?.ad?.date,
      type: FileType.IMAGE,
      files: news.detail?.files,
    };

    setForm(data);

    const files: FileItem[] = [];
    if (news.detail?.files) {
      news.detail?.files.map(({ file }: { file: models.FileItem }) => {
        files.push({
          name: file.name,
          extension: file.extension,
          type: FileType.IMAGE,
          base64: file.base64,
          url: file.url,
        });
      });
    }

    setFileList(files);
  };

  useEffect(() => {
    if (news && news.detail) {
      setInitialData();
    } else {
      setForm(initialValues);
    }
  }, [news.detail]);

  useEffect(() => {
    if (pageType === PAGE_TYPE.EDIT) {
      dispatch(NewsActions.getDetail(params.id));
    } else {
      dispatch(NewsActions.cleanDetails());
      setFileList([]);
    }
  }, [pathname, pageType]);

  return (
    <Container fluid className="details">
      <Row>
        <Col lg={12}>
          <PanelContentHeader
            pageTitle={translate('PAGES.PANEL.NEWS_ADD.REPORT.PAGE_TITLE')}
            pageDescription={translate(
              'PAGES.PANEL.NEWS_ADD.REPORT.PAGE_DESCRIPTION'
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={8}>
          <AdvancedForm onSubmit={onFormSubmit}>
            <div className="details__form">
              <div className="details__form__item">
                <Row>
                  <Col lg={12}>
                    <label>
                      {translate(
                        'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.UPLOAD'
                      )}
                    </label>
                    <AdvancedUploader
                      onChange={(file) => handleSetFileList(file)}
                      hidePreview
                    >
                      <div className="advanced-file-upload__preview-container">
                        {fileList &&
                          fileList.length > 0 &&
                          fileList.map((item: any) => (
                            <div
                              key={item.uid}
                              className="advanced-file-upload__preview"
                            >
                              <img
                                key={item.id}
                                className="advanced-file-upload__preview__img"
                                src={item.base64 || item.url}
                                alt="image_preview"
                              />
                            </div>
                          ))}
                      </div>
                      <div>
                        <AdvancedButton
                          className="panel-content-add-item__items__item__button panel-content-add-item__items__item--add"
                          text={translate(
                            'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.BUTTON_UPLOAD_MULTIPLE'
                          )}
                          endIcon={<CloudUploadOutlined />}
                        />
                      </div>
                    </AdvancedUploader>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col lg={12}>
                  <AdvancedInput
                    onChange={(value: string) => onFormChange('title', value)}
                    value={form?.title}
                    label={translate(
                      'PAGES.PANEL.NEWS_ADD.REPORT.ADD.LABELS.TITLE'
                    )}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <AdvancedInput
                    onChange={(value: string) =>
                      onFormChange('description', value)
                    }
                    multiline={10}
                    rows={6}
                    value={form?.description}
                    label={translate(
                      'PAGES.PANEL.NEWS_ADD.REPORT.ADD.LABELS.DESCRIPTION'
                    )}
                  />
                </Col>
              </Row>
              {/* <Row>
                <Col>
                  <AdvancedButton
                    className="panel-content-add-item__items__item__button panel-content-add-item__items__item--add advanced-button-modified"
                    type="submit"
                    text={translate(
                      'PAGES.PANEL.NEWS_ADD.REPORT.ADD.LABELS.BUTTON'
                    )}
                    endIcon={<CheckCircleOutlined />}
                  />
                </Col>
              </Row> */}
              <div className="details__form__actions">
                <Row>
                  <Col md={6}>
                    <Link to={getRouteStackPath('ACTIVITIES', 'NEWS_REPORT')}>
                      <AdvancedButton
                        variant="text"
                        text={translate('SHARED.BACK')}
                        startIcon={<LeftOutlined />}
                      />
                    </Link>
                  </Col>
                  <Col md={6} className="text-right">
                    <AdvancedButton
                      type="submit"
                      text={translate('SHARED.SAVE')}
                      endIcon={<PlusCircleOutlined />}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </AdvancedForm>
        </Col>
      </Row>
    </Container>
  );
};

export default NewsDetails;
