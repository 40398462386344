import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import { translate } from '~/services/i18n';

import NewsDetails from './Details/NewsDetails';
import NewsReport from './Report/NewsReport';
import { getPagetType } from '@portal/utils/page';
import { PAGE_TYPE } from '@portal/enum/pageType';

const NewsNavigationStack = () => {
  return (
    <Switch>
      <Route path={getRouteStackPath('ACTIVITIES', 'NEWS_REPORT')}>
        <PanelContent
          pageTitle={translate('PAGES.PANEL.NEWS.REPORT.PAGE_TITLE')}
          pageDescription={translate(
            'PAGES.PANEL.NEWS.REPORT.PAGE_DESCRIPTION'
          )}
        >
          <NewsReport />
        </PanelContent>
      </Route>
      <Route path={`${getRouteStackPath('ACTIVITIES', 'NEWS_DETAILS')}/:id`}>
        <PanelContent
          pageTitle={translate(`PAGES.PANEL.NEWS.DETAILS.TITLE_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
          pageDescription={translate(`PAGES.PANEL.NEWS.DETAILS.PAGE_DESCRIPTION_${getPagetType() === PAGE_TYPE.ADD ? 'ADD' : 'EDIT'}`)}
        >
          <NewsDetails />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default NewsNavigationStack;
