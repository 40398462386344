import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { getMe } from '~/actions/user';
import PanelNavigationStack from '~/containers/Panel/PanelNavigationStack';
import AuthNavigationStack from '~/pages/Auth/AuthNavigationStack';

interface IProps {
  isLogged: boolean;
}

const Router: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.isLogged) {
      dispatch(getMe());
    }
  }, [props.isLogged]);

  return (
    <BrowserRouter>
      <div>
        <Switch>
          {props.isLogged ? (
            <Route path="/">
              <PanelNavigationStack />
            </Route>
          ) : (
            <Route path="/">
              <AuthNavigationStack />
            </Route>
          )}
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default Router;
