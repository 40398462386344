/* tslint:disable:object-literal-sort-keys */
import { USER_PAGE_TYPE } from '~/enum/pageType';

const routes: models.route[] = [
  {
    id: 'DASHBOARD',
    name: 'PAGES.PANEL.DASHBOARD.SIDEBAR_TITLE',
    route: '/dashboard',
    icon: '/assets/svg/panel-sidebar/ic_dashboard.svg',
    iconAlt: 'Dashboard',
    items: [
      {
        id: 'DETAILS',
        name: 'Detalhes',
        route: '/detalhes',
      },
    ],
  },
  {
    id: 'USER',
    name: 'PAGES.PANEL.USER.SIDEBAR_TITLE',
    route: '/usuarios',
    icon: '/assets/svg/panel-sidebar/ic_users.svg',
    iconAlt: 'Usuários',
    items: [
      {
        id: 'ADMIN_REPORT',
        name: 'Administradores',
        route: '/administradores/lista',
        accessType: USER_PAGE_TYPE.ADMIN,
      },
      {
        id: 'ADMIN_DETAILS',
        name: 'Detalhes',
        route: '/administradores/detalhes',
        accessType: USER_PAGE_TYPE.ADMIN,
        sidebarHidden: true,
      },

      {
        id: 'USER_REPORT',
        name: 'Usuários',
        route: '/usuarios/lista',
        accessType: USER_PAGE_TYPE.ADMIN,
      },
      {
        id: 'USER_DETAILS',
        name: 'Detalhes',
        route: '/usuarios/detalhes',
        accessType: USER_PAGE_TYPE.ADMIN,
        sidebarHidden: true,
      },
      {
        id: 'SYNOD_REPORT',
        name: 'Sínodos',
        route: '/sinodos/lista',
        accessType: USER_PAGE_TYPE.ADMIN,
      },
      {
        id: 'SYNOD_DETAILS',
        name: 'Sínodos',
        route: '/sinodos/detalhes',
        sidebarHidden: true,
        accessType: USER_PAGE_TYPE.ADMIN,
      },
      {
        id: 'PARISH_REPORT',
        name: 'Paróquias',
        route: '/paroquias/lista',
      },
      {
        id: 'PARISH_DETAILS',
        name: 'Paróquias',
        route: '/paroquias/detalhes',
        sidebarHidden: true,
      },
      {
        id: 'LEADER_REPORT',
        name: 'Lideranças',
        route: '/liderancas/lista',
      },
      {
        id: 'LEADER_DETAILS',
        name: 'liderança',
        route: '/liderancas/detalhes',
        sidebarHidden: true,
      },
      {
        id: 'COMMUNITY',
        name: 'Comunidades',
        route: '/comunidade/lista',
      },
      {
        id: 'COMMUNITY_REPORT',
        name: 'Comunidades',
        route: '/comunidade/lista',
        sidebarHidden: true,
      },
      {
        id: 'COMMUNITY_DETAILS',
        name: 'Comunidades',
        route: '/comunidade/detalhes',
        sidebarHidden: true,
      },
    ],
  },
  {
    id: 'MEU_SINODO',
    name: 'MEU SÍNODO',
    route: '/meu-sinodo',
    icon: '/assets/svg/panel-sidebar/ic_my_synod.svg',
    iconAlt: 'Meu Sínodo',
    accessType: USER_PAGE_TYPE.USER,
    items: [
      {
        id: 'INITIAL',
        name: 'Início',
        route: '/inicio',
      },
      {
        id: 'LEADERS',
        name: 'Lideranças',
        route: '/liderancas',
      },
      {
        id: 'OCCUPATION_AREA',
        name: 'Áreas de atuação',
        route: '/areas-de-atuacao',
      },
    ],
  },
  {
    id: 'INSTITUCIONAL',
    name: 'INSTITUCIONAL',
    route: '/institucional',
    icon: '/assets/svg/panel-sidebar/ic_enterprise.svg',
    iconAlt: 'Institucional',
    accessType: USER_PAGE_TYPE.ADMIN,
    items: [
      {
        id: 'IECLB',
        name: 'IECLB',
        route: '/ieclb',
      },
      {
        id: 'ORGANIZATION',
        name: 'Organização',
        route: '/organizacao',
      },
      {
        id: 'ECUMENISM',
        name: 'Ecumenismo',
        route: '/ecumenismo',
      },
      {
        id: 'INSTITUTION',
        name: 'Instituições',
        route: '/instituicoes',
      },
      {
        id: 'DETALHES',
        name: 'Detalhes',
        route: '/detalhes',
        sidebarHidden: true,
      },
    ],
  },
  {
    id: 'ACTIVITIES',
    name: 'PAGES.PANEL.NEW_ACTIVITIES.SIDEBAR_TITLE',
    route: '/atividades',
    icon: '/assets/svg/panel-sidebar/ic_activities.svg',
    iconAlt: 'Atividades',
    items: [
      {
        id: 'SCHEDULE_REPORT',
        name: 'Agendas & Eventos',
        route: '/agenda/lista',
      },
      {
        id: 'SCHEDULE_DETAILS',
        name: 'Agendas & Eventos',
        route: '/agenda/detalhes',
        sidebarHidden: true,
      },
      {
        id: 'NEWS',
        name: 'Notícias',
        route: '/noticia/lista',
      },
      {
        id: 'NEWS_REPORT',
        name: 'Notícias',
        route: '/noticia/lista',
        sidebarHidden: true,
      },
      {
        id: 'NEWS_DETAILS',
        name: 'Notícias',
        route: '/noticia/detalhes',
        sidebarHidden: true,
      },
      {
        id: 'NEWS_ADD',
        name: 'Notícias',
        route: '/noticia/detalhes/add',
        sidebarHidden: true,
      },
      {
        id: 'PASSWORDS',
        name: 'Senhas Diárias',
        route: '/senhas-diarias/lista',
        accessType: USER_PAGE_TYPE.ADMIN,
      },
      {
        id: 'PASSWORDS_REPORT',
        name: 'Senhas Diárias',
        route: '/senhas-diarias/lista',
        sidebarHidden: true,
        accessType: USER_PAGE_TYPE.ADMIN,
      },
      {
        id: 'PASSWORDS_DETAILS',
        name: 'Senhas Diárias',
        route: '/senhas-diarias/detalhes',
        sidebarHidden: true,
      },
      {
        id: 'NOTIFICATIONS',
        name: 'Notificações',
        route: '/notificacoes/lista',
        accessType: USER_PAGE_TYPE.ADMIN,
      },
      {
        id: 'NOTIFICATIONS_REPORT',
        name: 'Senhas Diárias',
        route: '/notificacoes/lista',
        sidebarHidden: true,
      },
      {
        id: 'NOTIFICATIONS_DETAILS',
        name: 'Senhas Diárias',
        route: '/notificacoes/detalhes',
        sidebarHidden: true,
      },
    ],
  },
];

export const getRoutes = (): models.route[] => routes;

export const getRoutestack = (route: string): models.route =>
  routes.find((o) => o.route === route) as models.route;

export const getStackPath = (stackId: string): string => {
  return `${routes.find((o) => o.id === stackId)?.route}`;
};

export const routeExist = (route: string): boolean => {
  const routeTop = routes.find((o) => route.startsWith(o.route));

  if (!routeTop) {
    return false;
  }
  if (routeTop.route.length === route.length) {
    return false;
  }

  return (
    (routeTop.items.find((o) => `${routeTop.route}${o.route}` === route) &&
      true) ||
    false
  );
};

export const getRouteStackPath = (stackId: string, routeId: string): string => {
  const route = routes.find((o) => o.id === stackId);

  return `${route?.route}${route?.items.find((o) => o.id === routeId)?.route}`;
};
