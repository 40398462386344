import React from 'react';
import { Col, Row } from 'react-bootstrap';

import MySynodForm from '@portal/components/MySynodForm/MySynodForm';
import PanelContentHeader from '@portal/components/PanelContentHeader/PanelContentHeader';
import MySynodOccupationAreaImage from '~/assets/png/my_synod_occupation_area.png';
import { translate } from '~/services/i18n';

const OccupationAreaReport: React.FC = () => {
  return (
    <div className="initial">
      <Row>
        <Col lg={12}>
          <PanelContentHeader
            pageTitle={translate('PAGES.PANEL.MY_SYNOD.OCCUPATION_AREA.PAGE_TITLE')}
            pageDescription={translate(
              'PAGES.PANEL.MY_SYNOD.OCCUPATION_AREA.PAGE_DESCRIPTION'
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <MySynodForm
            title
            description
            sender='occup'
          />
        </Col>
        <Col lg={6}>
          <img src={MySynodOccupationAreaImage} />
        </Col>
      </Row>
    </div>
  );
};

export default OccupationAreaReport;