import React, { useState } from 'react';
import { maskDate, maskTime } from '@portal/services/masks';
import { Col, Container, Row } from 'react-bootstrap';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  CheckCircleOutlined,
  LeftOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';

import * as NotificationActions from '~/actions/notification';
import { getRouteStackPath } from '@portal/config/routes';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import AdvancedForm from '~/components/AdvancedForm/AdvancedForm';
import AdvancedInput from '~/components/AdvancedInput/AdvancedInput';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import PanelContentBreadcrumb from '~/components/PanelContentBreadcrumb/PanelContentBreadcrumb';
import { AddNotifications } from '~/models/module';
import { translate } from '~/services/i18n';
import { formatDateTime } from '@portal/utils/format';
// import { decreaseLoading, increaseLoading } from '~/actions/loading';

const initialValues: AddNotifications = {
  title: '',
  body: '',
  notificationType: null,
  scheduledDate: '',
  synodId: null,
  userIdList: null,
  sendDate: null,
  sendTime: null,
};

const NotificationsDetails: React.FC = (props) => {
  const [form, setForm] = useState(initialValues);
  const dispatch = useDispatch();

  const onFormChange = (key: string, value: string) => {
    setForm((prevState: models.AddNotifications) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const onFormSubmit = () => {
    dispatch(NotificationActions.addNotification({ ...form }));
  };

  return (
    <Container fluid className="details">
      <Row>
        <Col>
          <PanelContentBreadcrumb
            items={[
              {
                title: translate('PAGES.PANEL.NOTIFICATIONS.TITLE'),
                url: getRouteStackPath('ACTIVITIES', 'NOTIFICATIONS'),
                active: true,
              },
              {
                title: translate('PAGES.PANEL.PASSWORDS.REPORT.BUTTON_LINK'),
                active: false,
              },
            ]}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <PanelContentHeader
            pageTitle={translate('PAGES.PANEL.NOTIFICATIONS.REPORT.PAGE_TITLE')}
            pageDescription={translate(
              'PAGES.PANEL.NOTIFICATIONS.REPORT.PAGE_DESCRIPTION'
            )}
          />
        </Col>
      </Row>
      <div className="notifications__space"></div>
      <Row>
        <Col lg={8}>
          <AdvancedForm onSubmit={onFormSubmit}>
            <div className="notifications__space"></div>
            <Row>
              <Col lg={12}>
                <AdvancedInput
                  onChange={(value: string) => onFormChange('title', value)}
                  value={form.title}
                  label={translate(
                    'PAGES.PANEL.NOTIFICATIONS.REPORT.ADD.LABELS.TITLE'
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <AdvancedInput
                  onChange={(value: string) => onFormChange('body', value)}
                  multiline={10}
                  rows={6}
                  value={form.body}
                  label={translate(
                    'PAGES.PANEL.NOTIFICATIONS.REPORT.ADD.LABELS.DESCRIPTION'
                  )}
                />
              </Col>
            </Row>
            <div className="notifications__space">
              <Row>
                <Col lg={6}>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.NOTIFICATIONS.REPORT.ADD.LABELS.DATE'
                    )}
                    onChange={(value: string) =>
                      onFormChange('sendDate', value)
                    }
                    value={maskDate(form && form.sendDate)}
                    placeholder={translate(
                      'PAGES.PANEL.NOTIFICATIONS.DETAILS.PLACEHOLDER_DATE'
                    )}
                  />
                </Col>
                <Col>
                  <AdvancedInput
                    label={translate(
                      'PAGES.PANEL.SCHEDULE_DETAILS.REPORT.ADD.LABELS.TIME_BEGIN'
                    )}
                    onChange={(value: string) =>
                      onFormChange('sendTime', value)
                    }
                    value={maskTime(form && form.sendTime)}
                    placeholder={translate(
                      'PAGES.PANEL.NOTIFICATIONS.DETAILS.PLACEHOLDER_TIME'
                    )}
                  />
                </Col>
              </Row>
            </div>
            <div className="details__form__actions">
              <Row>
                <Col md={6}>
                  <Link
                    to={getRouteStackPath('ACTIVITIES', 'NOTIFICATIONS_REPORT')}
                  >
                    <AdvancedButton
                      variant="text"
                      text={translate('SHARED.BACK')}
                      startIcon={<LeftOutlined />}
                    />
                  </Link>
                </Col>
                <Col md={6} className="text-right">
                  <AdvancedButton
                    type="submit"
                    text={translate('SHARED.SAVE')}
                    endIcon={<PlusCircleOutlined />}
                  />
                </Col>
              </Row>
            </div>
          </AdvancedForm>
        </Col>
      </Row>
    </Container>
  );
};

export default NotificationsDetails;
