import { API_URL } from '@portal/config/env';
import { INotificationsAdvancedFilter } from '@portal/models/advanced-filters';

import getInstance from './instance';

const NotificationApi = {
  getNotifications: async (params: INotificationsAdvancedFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get(`${API_URL}/notification`, { params });

    return data;
  },

  addNotification: async (payload: models.NotificationApi) => {
    const instance = await getInstance();
    await instance.post(`${API_URL}/notification`, payload);
  },
};

export default NotificationApi;
