import { CellParams } from '@material-ui/data-grid';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import * as SynodActions from '~/actions/synod';
import * as ParishActions from '~/actions/parish';
import * as CommunityActions from '~/actions/community';
import ChurchCardIconOne from '~/assets/svg/details-cards/ic_church_card_one.svg';
import DataTable from '~/components/DataTable/DataTable';
import DataTableActions from '~/components/DataTableActions/DataTableActions';
import KPICard from '~/components/KPICard/KPICard';
import PanelContentHeader from '~/components/PanelContentHeader/PanelContentHeader';
import { REPORT_PAGE_SIZE } from '~/config/env';
import { translate } from '~/services/i18n';
import NavigationService from '~/services/navigation';

const initialValues: advancedFilterModels.ISynodAdvancedFilter = {
  name: '',
  orderBy: 'createdAt',
  page: 1,
  pageSize: REPORT_PAGE_SIZE,
  sort: 'desc',
};
const parishValues: advancedFilterModels.IParishAdvancedFilter = {
  name: '',
  orderBy: 'createdAt',
  page: 1,
  pageSize: REPORT_PAGE_SIZE,
  sort: 'desc',
};
const communityValues: advancedFilterModels.ICommunityAdvancedFilter = {
  name: '',
  orderBy: 'createdAt',
  page: 1,
  pageSize: REPORT_PAGE_SIZE,
  sort: 'desc',
};

const PanelDashboardDetails: React.FC = () => {
  const dispatch = useDispatch();
  const [advancedFilters, setAdvancedFilters] = useState(initialValues);

  useEffect(() => {
    dispatch(ParishActions.getReport(parishValues));
    dispatch(CommunityActions.getReport(communityValues));
  }, []);

  const synodRows = useSelector(
    (state: reducers.rootReducer) => state.synod.list
  );

  const parishRows = useSelector(
    (state: reducers.rootReducer) => state.parish.list
  );

  const communityRows = useSelector(
    (state: reducers.rootReducer) => state.community.list
  );

  const me = useSelector((state: reducers.rootReducer) => state.auth.me);

  useEffect(() => {
    const filter = NavigationService.getQuery();

    onSearch({
      ...advancedFilters,
      ...filter,
    });
  }, []);

  const onSearch = (filters: advancedFilterModels.ISynodAdvancedFilter) => {
    dispatch(SynodActions.getReport(filters));
  };

  return (
    <div className="report">
      <div className="report__header">
        {translate('PAGES.PANEL.DASHBOARD.DETAILS.WELCOME')}
        {me?.name}
      </div>
      <div className="panel-dashboard__title">
        <PanelContentHeader
          pageTitle={translate('PAGES.PANEL.DASHBOARD.DETAILS.TITLE')}
          pageDescription={translate(
            'PAGES.PANEL.DASHBOARD.DETAILS.PAGE_DESCRIPTION'
          )}
        />
      </div>

      <div className="report__header">
        <Row>
          <Col lg={4}>
            <KPICard
              icon={ChurchCardIconOne}
              number={synodRows?.count || 0}
              description={translate(
                'PAGES.PANEL.DASHBOARD.DETAILS.CARDS.ONE.DESCRIPTION'
              )}
            />
          </Col>
          <Col lg={4}>
            <KPICard
              icon={ChurchCardIconOne}
              number={parishRows?.count || 0}
              description={translate(
                'PAGES.PANEL.DASHBOARD.DETAILS.CARDS.TWO.DESCRIPTION'
              )}
            />
          </Col>
          <Col lg={4}>
            <KPICard
              icon={ChurchCardIconOne}
              number={communityRows?.count || 0}
              description={translate(
                'PAGES.PANEL.DASHBOARD.DETAILS.CARDS.THREE.DESCRIPTION'
              )}
            />
          </Col>
        </Row>
      </div>

      <div className="report__header" style={{ marginTop: '15px' }}>
        <PanelContentHeader
          pageTitle={translate('PAGES.PANEL.DASHBOARD.DETAILS.TABLE.TITLE')}
          pageDescription={translate(
            'PAGES.PANEL.DASHBOARD.DETAILS.TABLE.DESCRIPTION'
          )}
        />
      </div>
    
      <div className="report__table">
        <Row>
          <Col>
            <div className="report__table__inner">
              <DataTable
                rows={synodRows?.rows || []}
                rowCount={synodRows?.count || 0}
                columns={[
                  {
                    field: 'id',
                    flex: 1,
                    headerName: 'id',
                    sortable: false,
                    hide: true,
                  },
                  {
                    field: 'name',
                    flex: 1,
                    headerName: 'Sínodo',
                  },
                  {
                    field: 'leadershipTitle',
                    flex: 1,
                    headerName: 'Administrador',
                  },
                  {
                    field: 'createdAt',
                    flex: 1,
                    headerName: 'Criação',
                    renderCell: (o: CellParams) => {
                      return (
                        <>
                          {DateTime.fromISO(o.value as string).toLocaleString(
                            DateTime.DATETIME_SHORT
                          )}
                        </>
                      );
                    },
                  },
                  {
                    align: 'center',
                    field: 'actions',
                    headerAlign: 'center',
                    headerName: 'Ações',
                    renderCell: (o: CellParams) => {
                      return (
                        <DataTableActions
                          row={o.row}
                          basePath="/usuarios/sinodo/detalhes/"
                        />
                      );
                    },
                  },
                ]}
                pageSize={advancedFilters.pageSize}
                page={advancedFilters.page}
                sort={advancedFilters.sort}
                orderBy={advancedFilters.orderBy}
                onChange={(filters) => {
                  const searchFilters = {
                    ...advancedFilters,
                    ...filters,
                  };
                  setAdvancedFilters(searchFilters);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PanelDashboardDetails;
