import getInstance from './instance';
import { ISynodAdvancedFilter } from '~/models/advanced-filters';

const BASE_URL: string = '/synod';

const SynodApi = {

  getReport: async (params: advancedFilterModels.ISynodAdvancedFilter) => {
    const instance = await getInstance();
    const { data } = await instance.get(BASE_URL, { params });

    return data;
  },

  getDetail: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.get(`${BASE_URL}/${id}`);

    return data;
  },

  create: async (params: models.Synod) => {
    const instance = await getInstance();
    const { data } = await instance.post(BASE_URL, params);

    return data;
  },

  remove: async (id: string) => {
    const instance = await getInstance();
    const { data } = await instance.delete(`${BASE_URL}/${id}`);

    return data;
  },

  update: async (id?: string, params?: any) => {
    const instance = await getInstance();
    const { data } = await instance.put(`${BASE_URL}/${id}`, params);

    return data;
  },

  getAllSynods: async (params: ISynodAdvancedFilter) => {
    const instance = getInstance();
    const { data } = await instance.get(`${BASE_URL}/all`, { params });

    return data;
  },

  associateToSynod: async (id?: string, userId?: string) => {
    const instance = getInstance();
    const { data } = await instance.post(`${BASE_URL}/${id}/associate-admin`, { userId })

    return data;
  }
};

export default SynodApi;
