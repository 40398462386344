import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { getRouteStackPath } from '@portal/config/routes';
import PanelContent from '~/components/PanelContent/PanelContent';
import EcumenismReport from '~/pages/Institutional/EcumenismReport/EcumenismReport';
import IeclbReport from '~/pages/Institutional/IeclbReport/IeclbReport';
import InstitutionReport from '~/pages/Institutional/InstitutionReport/InstitutionReport';
import OrganizationReport from '~/pages/Institutional/OrganizationReport/OrganizationReport';
import { translate } from '~/services/i18n';

const InstitutionalNavigationStack = () => {
  return (
    <Switch>
      <Route
        path={getRouteStackPath(
          translate('PAGES.PANEL.IECLB.ROUTE.NAME'),
          translate('PAGES.PANEL.IECLB.ROUTE.REPORT')
        )}
      >
        <PanelContent
          pageTitle={translate('PAGES.PANEL.IECLB.REPORT.PAGE_TITLE')}
          pageDescription={translate(
            'PAGES.PANEL.IECLB.REPORT.PAGE_DESCRIPTION'
          )}
        >
          <IeclbReport />
        </PanelContent>
      </Route>
      <Route
        path={getRouteStackPath(
          translate('PAGES.PANEL.ORGANIZATION.ROUTE.NAME'),
          translate('PAGES.PANEL.ORGANIZATION.ROUTE.REPORT')
        )}
      >
        <PanelContent
          pageTitle={translate('PAGES.PANEL.ORGANIZATION.REPORT.PAGE_TITLE')}
          pageDescription={translate(
            'PAGES.PANEL.ORGANIZATION.REPORT.PAGE_DESCRIPTION'
          )}
        >
          <OrganizationReport />
        </PanelContent>
      </Route>
      <Route
        path={getRouteStackPath(
          translate('PAGES.PANEL.ECUMENISM.ROUTE.NAME'),
          translate('PAGES.PANEL.ECUMENISM.ROUTE.REPORT')
        )}
      >
        <PanelContent
          pageTitle={translate('PAGES.PANEL.ECUMENISM.REPORT.PAGE_TITLE')}
          pageDescription={translate(
            'PAGES.PANEL.ECUMENISM.REPORT.PAGE_DESCRIPTION'
          )}
        >
          <EcumenismReport />
        </PanelContent>
      </Route>
      <Route
        path={getRouteStackPath(
          translate('PAGES.PANEL.INSTITUTION.ROUTE.NAME'),
          translate('PAGES.PANEL.INSTITUTION.ROUTE.REPORT')
        )}
      >
        <PanelContent
          pageTitle={translate('PAGES.PANEL.INSTITUTION.REPORT.PAGE_TITLE')}
          pageDescription={translate(
            'PAGES.PANEL.INSTITUTION.REPORT.PAGE_DESCRIPTION'
          )}
        >
          <InstitutionReport />
        </PanelContent>
      </Route>
    </Switch>
  );
};

export default InstitutionalNavigationStack;
