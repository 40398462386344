import { RightCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { update } from '@portal/actions/synod';
import AdvancedButton from '~/components/AdvancedButton/AdvancedButton';
import { translate } from '~/services/i18n';

import AdvancedForm from '../AdvancedForm/AdvancedForm';
import AdvancedInput from '../AdvancedInput/AdvancedInput';
import { getMe } from '@portal/actions/user';

interface IMySynodForm {
  url?: boolean;
  title?: boolean;
  description?: boolean;
  otherInfo?: boolean;
  sender: 'init' | 'lead' | 'occup';
}

const MySynodForm = ({
  title,
  description,
  otherInfo,
  url,
  sender,
}: IMySynodForm) => {
  const dispatch = useDispatch();
  const me = useSelector((state: reducers.rootReducer) => state.user.me);

  const [loading, setLoading] = useState(true);
  const [formUrl, setUrl] = useState('');
  const [formTitle, setTitle] = useState('');
  const [formDescription, setDescription] = useState('');
  const [formOtherInfo, setOtherInfo] = useState('');

  const synod = me?.parish?.synod;

  const onFormSubmit = () => {
    if (synod) {
      if (sender === 'init') {
        dispatch(
          update(synod.id, {
            videoUrl: formUrl,
            initTitle: formTitle,
            initDescription: formDescription,
            moreInfo: formOtherInfo,
          })
        );
      } else if (sender === 'lead') {
        dispatch(
          update(synod.id, {
            leadershipTitle: formTitle,
            leadershipDescription: formDescription,
          })
        );
      } else if (sender === 'occup') {
        dispatch(
          update(synod.id, {
            occupAreaTitle: formTitle,
            occupAreaDescription: formDescription,
          })
        );
      }
    }
  };

  const setValues = () => {
    if (synod) {
      if (sender === 'init') {
        setTitle(synod.initTitle);
        setDescription(synod.initDescription);
        setUrl(synod.videoUrl);
        setOtherInfo(synod.moreInfo);
      } else if (sender === 'lead') {
        setTitle(synod.leadershipTitle);
        setDescription(synod.leadershipDescription);
      } else if (sender === 'occup') {
        setTitle(synod.occupAreaTitle);
        setDescription(synod.occupAreaDescription);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (loading) {
      setValues();
      dispatch(getMe());
    }
  }, [me]);

  return (
    <div className="my-synod-form">
      <div className="my-synod-form__items">
        <AdvancedForm onSubmit={onFormSubmit}>
          <div className="my-synod-form__items__item my-synod-form__items__item--add">
            {url && (
              <Row>
                <Col lg={12}>
                  <AdvancedInput
                    onChange={setUrl}
                    value={formUrl}
                    label={translate('PAGES.PANEL.MY_SYNOD.FORM.URL')}
                  />
                </Col>
              </Row>
            )}
            {title && (
              <Row>
                <Col lg={12}>
                  <AdvancedInput
                    onChange={setTitle}
                    value={formTitle}
                    label={translate('PAGES.PANEL.MY_SYNOD.FORM.TITLE')}
                  />
                </Col>
              </Row>
            )}
            {description && (
              <Row>
                <Col lg={12}>
                  <AdvancedInput
                    onChange={setDescription}
                    value={formDescription}
                    multiline={10}
                    rows={6}
                    label={translate('PAGES.PANEL.MY_SYNOD.FORM.DESCRIPTION')}
                  />
                </Col>
              </Row>
            )}
            {otherInfo && (
              <Row>
                <Col lg={12}>
                  <AdvancedInput
                    onChange={setOtherInfo}
                    value={formOtherInfo}
                    multiline={10}
                    rows={6}
                    label={translate('PAGES.PANEL.MY_SYNOD.FORM.OTHER_INFO')}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col lg={12} className="text-right">
                <AdvancedButton
                  className="my-synod-form__items__item__button my-synod-form__items__item--add"
                  type="submit"
                  text={translate('PAGES.PANEL.INSTITUTIONAL.FORM.BUTTON')}
                  endIcon={<RightCircleOutlined />}
                />
              </Col>
            </Row>
          </div>
        </AdvancedForm>
      </div>
    </div>
  );
};

export default MySynodForm;
