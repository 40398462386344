import NewsRequests from '~/repositories/news';
import { NEWS_DETAIL, NEWS_REPORT } from './actionTypes';
import * as MessageService from '~/services/message';
import { decreaseLoading, increaseLoading } from './loading';
import centralNavigationService from '~/services/navigation';
import { AddNews } from '@portal/models/module';

export const cleanDetails = () => async (dispatch: any) => {
  await dispatch({
    payload: null,
    type: NEWS_DETAIL,
  });
};

export const getReport = (
  searchParams: advancedFilterModels.INewsAdvancedFilter
) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.PaginationResponse<models.News> = await NewsRequests.getReport(
      searchParams
    );

    dispatch({
      payload,
      type: NEWS_REPORT,
    });
  } catch (err) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const getDetail = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    const payload: models.Admin = await NewsRequests.getDetail(id);

    dispatch({
      payload,
      type: NEWS_DETAIL,
    });
  } catch (err) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const create = (params: AddNews) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await NewsRequests.create(params);

    MessageService.success('PAGES.PANEL.NEWS.MESSAGES.SUCCESS_ADD_MESSAGE');
    await centralNavigationService.back();
  } catch (err) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const remove = (id: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await NewsRequests.remove(id);
    MessageService.success('PAGES.PANEL.NEWS.MESSAGES.SUCCESS_DELETE_MESSAGE');
  } catch (err) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};

export const update = (id?: string, params?: any) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await NewsRequests.update(id, params);

    MessageService.success('PAGES.PANEL.NEWS.MESSAGES.EDIT_MESSAGE');
    await centralNavigationService.back();
  } catch (err) {
    MessageService.apiError(err.message);
  } finally {
    dispatch(decreaseLoading());
  }
};
