import React from 'react';

interface IProps {
  icon?: any;
  number?: any;
  description?: any;
}

const KPICard: React.FC<IProps> = (props: IProps) => {
  return (
    <div className="kpi-card">
      <div className="kpi-card__icon">
        <img
          className="kpi-card__icon__img"
          src={props.icon}
          alt="kpi card icon"
        />
      </div>
      <div className="kpi-card__right">
        <div className="kpi-card__right__number">
          {props.number}
        </div>
        <div className="kpi-card__right__description">
          {props.description}
        </div>
      </div>
    </div>
  );
};

export default KPICard;
