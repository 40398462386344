import Axios from 'axios';

const BASE_URL_VIA_CEP: string = 'https://viacep.com.br/ws/';

const ViaCep = {
  getAddressByCep: async (cep: string) => {
    return Axios.get(`${BASE_URL_VIA_CEP}/${cep}/json`);
  }
};

export {
  ViaCep,
};
